<template>
	<v-app class="app">
		<v-main>
			<Header />
			<router-view :key="$route.fullPath" class="biblioteca" />
			<Cookies />
		</v-main>
		<Footer />
	</v-app>
</template>

<script>
	import Header from "@/components/ui/Header.vue";
	import Footer from "@/components/ui/Footer.vue";
	import Cookies from "@/components/ui/Cookies.vue";
	export default {
		components: {
			Header,
			Footer,
			Cookies
		},
		mounted() {
			this.updateWindowsProperties();
			window.addEventListener("resize", () => {
				this.updateWindowsProperties();
			});

			let scrollingTimeout;

			window.addEventListener("scroll", () => {
				if (window.innerWidth < 1024) {
					if (window.scrollY > 40) {
						this.updateProperty({
							property: "droppedMenu",
							value: true
						});
					} else {
						this.updateProperty({
							property: "droppedMenu",
							value: false
						});
					}
					this.updateProperty({
						property: "timeoutedMenu",
						value: false
					});

					clearTimeout(scrollingTimeout);
					scrollingTimeout = setTimeout(() => {
						this.updateProperty({
							property: "timeoutedMenu",
							value: true
						});
					}, 3000);
				}
			});
		},

		beforeMount() {
			this.updateProperty({
				property: "loadingStatus",
				value: false
			});
		},
		methods: {
			updateWindowsProperties() {
				this.updateProperty({
					property: "windowWidth",
					value: window.innerWidth
				});
				this.updateProperty({
					property: "windowHeight",
					value: window.innerHeight
				});

				this.updateProperty({
					property: "dotsNumberX",
					value: window.innerWidth / this.columnWidth
				});
				this.updateProperty({
					property: "dotsNumberY",
					value: window.innerHeight / this.columnWidth
				});
			}
		}
	};
</script>

<style lang="scss">
	#app.theme--light.v-application {
		color: black;
	}
	.biblioteca {
		padding-top: $menu-height;
	}

	@media only screen and (max-width: 1024px) {
		.biblioteca {
			padding-top: 0;
		}
	}
</style>
