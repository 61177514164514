import gql from 'graphql-tag';

const postFragment = gql`
	fragment postFragment on Post {
		id
		title
		date
		uri
		link
		categories {
			nodes {
				id
				name
				parentDatabaseId
				slug
			}
		}
		qzrTargets {
			nodes {
				name
				slug
			}
		}
		blocks {
			... on QzrstudioCategorySelectorBlock {
				dynamicContent
				originalContent
				attributes {
					fields
				}
			}
		}
		featuredImage {
			node {
				mediaItemUrl
				sourceUrl(size: CUSTOM_MEDIUM_SIZE)
				srcSet(size: MEDIUM)
				sizes
			}
		}
	}
`;

const postQZRTargetFragment = gql`
	fragment postQZRTargetFragment on Post {
		blocksJSON
		blocks {
			... on QzrstudioCategorySelectorBlock {
				dynamicContent
				originalContent
				attributes {
					fields
				}
			}
		}
		categories {
			nodes {
				name
				slug
				parentDatabaseId
				link
			}
		}	
		featuredImage {
			node {
				mediaItemUrl
				sourceUrl(size: CUSTOM_MEDIUM_SIZE)
				srcSet(size: MEDIUM)
				sizes
			}
		}
		qzrTargets {
			nodes {
				name
				slug
			}
		}
		title
		uri
	}
`;

function GET_POSTS_BY_QZR_TARGET(slug, category) {
	return gql`
	query GET_POSTS_BY_QZR_TARGET {
		qzrTargets(where: {slug: "${slug}"}) {
			nodes {
				posts(where: {categoryName: "${category}"}) {
                    nodes {
    				    ...postQZRTargetFragment
					}
					pageInfo {
						endCursor
						hasNextPage
						hasPreviousPage
						startCursor
					}
				}
			}
		}
    }
    ${postQZRTargetFragment}`;
}

function GET_LAST_POSTS_BY_QZR_TARGET(slug, category) {
	return gql`
	query MyQuery {
  qzrTargets(where: {slug:"${slug}"}) {
    nodes {
      
        posts(where: {categoryName: "${category}"}) {
          nodes {
			...postQZRTargetFragment
		  }
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				startCursor
			}
        }
      
    }
  }
}
${postQZRTargetFragment}`;
}

function GET_POSTS_BY_QZR_TARGET_WITH_STICKY(slug, category) {
	return gql`
	query GET_POSTS_BY_QZR_TARGET_WITH_STICKY {
		qzrTargets(where: {slug: "${slug}"}) {
			nodes {
				sticky: posts(where: {onlySticky: true, categoryName: "${category}"}) {
                    nodes {
    				    ...postQZRTargetFragment
                    }
				}
				notSticky: posts(where: {onlySticky: false, categoryName: "${category}"}) {
                    nodes {
    				    ...postQZRTargetFragment
					}
					pageInfo {
						endCursor
						hasNextPage
						hasPreviousPage
						startCursor
					}
				}                
			}
		}
    }
    ${postQZRTargetFragment}`;
}

function GET_POSTS_BY_QZR_TARGET_ALL_CATEGORIES(slug, postPerPage = 10, offset) {
	return gql`
	query GET_POSTS_BY_QZR_TARGET_ALL_CATEGORIES {
		qzrTargets(where: {slug: "${slug}"}) {
            nodes {
    			sticky: posts(where: {onlySticky: true}) {
                    nodes {
    				    ...postQZRTargetFragment
                    }
    			}
    			notSticky: posts(first: ${postPerPage} where: {onlySticky: false} after: "${offset}") {
                    nodes {
    				    ...postQZRTargetFragment
					}
					pageInfo {
						endCursor
						hasNextPage
						hasPreviousPage
						startCursor
					}
    			}
            }            
		}
    }
    ${postQZRTargetFragment}`;
}

function GET_POST(slug) {
	return gql`
	query POST {
		posts(where: {name: "${slug}"}) {
			nodes {
	 	 	id
			title
			seo {
				metaDesc
				metaKeywords
				title
				opengraphTitle
				opengraphSiteName
				opengraphPublisher
				opengraphType
				opengraphUrl
				opengraphPublishedTime
				opengraphModifiedTime
				opengraphImage {
				  id
				  uri
				  sourceUrl
				}
			}
			date
			uri
			link
			blocksJSON
			featuredImage {
				node {
					mediaItemUrl
					sourceUrl(size: LARGE)
					srcSet(size: MEDIUM)
					sizes
				}
			}
			qzrTargets {
				nodes {
					name
					slug
				}
			}                
			categories {
				nodes {
					name
					slug
					parentDatabaseId
					link
				}
			}
			tags{
				nodes {
					id
					name
					link
				}
			}                
		}
	}
	}
    `;
}

function GET_POST_PREVIEW(id) {
	return gql`
		query POST {
			post(idType: DATABASE_ID, id: "${id}", asPreview: true) {
				id
				title
				date
				uri
				link
				blocksJSON
				featuredImage {
					node {
						mediaItemUrl
						sourceUrl(size: LARGE)
						srcSet(size: MEDIUM)
						sizes
					}
				}
				qzrTargets {
                    nodes {
                        name
                        slug
                    }
			    }                
				categories {
					nodes {
						name
                        slug
                        parentDatabaseId
						link
					}
				}
                tags{
                    nodes {
                        id
                        name
                        link
                    }
                }                
			}
		}
    `;
}

function GET_POSTS(post_type = 'post', ids = []) {
	let query = `query GET_POSTS {`;
	for (let i = 0; i < ids.length; i++) {
		query += `
				id_${ids[i]}: ${post_type}(idType: DATABASE_ID, id: ${ids[i]}) {
					id
					title
					date
					uri
					qzrTargets {
						edges {
						  node {
							name
							slug
						  }
						}
					  }
					link
					categories {
						edges {
						  node {
							id
							name
							slug
							uri
						  }
						}
					}
						featuredImage {
						node {
							sizes(size: CUSTOM_MEDIUM_SIZE)
							sourceUrl
							srcSet(size: MEDIUM)
							sizes
						}
					}
				}
			`;
	}
	query = query + `}`;

	return gql(query);
}

function GET_LAST_POSTS(posts_per_page = 10, where = '') {
	let where_condition = where ? ', ' + where : '';
	return gql`
		query GET_LAST_POSTS {
			posts (first: ${posts_per_page} ${where_condition} ) {
				nodes {
                    ...postFragment
				}
			}
        }
	${postFragment}`;
}

function GET_LAST_POSTS_WITH_STICKY(where = '', postPerPage = 10, offset) {
	let where_condition_with_sticky = where
		? ', where:{' + where + ', onlySticky: true}'
		: 'where:{ onlySticky: true }';
	let where_condition_without_sticky = where
		? ', where:{' + where + ', onlySticky: false}'
		: 'where:{onlySticky: false}';

	return gql`
		query GET_LAST_POSTS {
			sticky: posts (first: ${postPerPage} ${where_condition_with_sticky} ) {
				nodes {
                    ...postFragment
				}
			}
			notSticky: posts (first: ${postPerPage} ${where_condition_without_sticky} after: "${offset}" ) {
				nodes {
					...postFragment
				}
				pageInfo {
					endCursor
					hasNextPage
					hasPreviousPage
					startCursor
				}
			}
        }
	${postFragment}`;
}

function GET_CATEGORIES() {
	return gql`
		query GET_CATEGORIES {
			categories {
				nodes {
					name
					slug
				}
			}
		}
	`;
}

export {
	GET_LAST_POSTS,
	GET_LAST_POSTS_WITH_STICKY,
	GET_POST,
	GET_POST_PREVIEW,
	GET_POSTS,
	GET_POSTS_BY_QZR_TARGET,
	GET_POSTS_BY_QZR_TARGET_WITH_STICKY,
	GET_POSTS_BY_QZR_TARGET_ALL_CATEGORIES,
	GET_CATEGORIES,
	GET_LAST_POSTS_BY_QZR_TARGET,
};
