<template>
	<MainColumn>
		<div>
			<div class="slider">
				<div class="header">
					<h2 class="qzr-title font-size-35">Novità dalla Biblioteca</h2>
					<div class="more" @click="onArchivesClick('novita')">
						Scoprili tutti
					</div>
				</div>
				<swiper
					ref="swiper"
					class="swipe"
					:options="swiperOption"
					@clickSlide="handleSwiperSlideClick"
					@slideChange="onSlideChange"
				>
					<swiper-slide
						v-for="(slide, i) in slides"
						:key="i"
						v-show="true"
						:class="'slide background-' + slide.target"
					>
						<ArticleCard
							:bottomAlign="true"
							:image="
								slide.featuredImage ? slide.featuredImage.node.srcSet : ''
							"
							:imageSizes="
								slide.featuredImage ? slide.featuredImage.node.sizes : ''
							"
							:isSubheaderVisible="true"
							:mainCategory="slide.mainCategory"
							:subCategory="slide.subCategory"
							:subtitle="slide.author"
							:target="slide.target"
							:title="slide.title"
						/>
					</swiper-slide>
				</swiper>
				<PaginationSlideshow
					slot="pagination"
					:currentIndex="currentIndex + 1"
					:numberOfPosts="numberOfPosts"
					:target="'white'"
					class="pagination"
					@onPaginationClick="onPaginationClick"
				></PaginationSlideshow>
			</div>
		</div>
	</MainColumn>
</template>

<script>
import { formatCategoriesAndTargets } from "@/mixins/formatCategoriesAndTargets";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import PaginationSlideshow from "@/components/ui/PaginationSlideshow.vue";
import "swiper/swiper-bundle.css";
import {
	GET_LAST_POSTS,
	GET_LAST_POSTS_BY_QZR_TARGET,
} from "@/queries/Posts.js";
import ArticleCard from "@/components/ui/ArticleCard.vue";

export default {
	mixins: [formatCategoriesAndTargets],
	props: ["block", "blocks", "parentBlock"],
	components: {
		Swiper,
		SwiperSlide,
		ArticleCard,
		PaginationSlideshow,
	},
	data() {
		return {
			currentIndex: 0,
			fields: [],
			slides: [],
			numberOfPosts: 0,
			swiperOption: {
				slidesPerView: 1,
				spaceBetween: 25,
				breakpoints: {
					550: {
						slidesPerView: 2,
					},
					768: {
						slidesPerView: 3,
						spaceBetween: 40,
					},
				},
				centeredSlides: false,
			},
		};
	},
	computed: {
		swiper() {
			return this.$refs.swiper.swiperInstance;
		},
	},
	methods: {
		onSlideChange() {
			this.currentIndex = this.swiper.activeIndex;
		},
		onPaginationClick(index) {
			this.currentIndex = index;
			this.swiper.slideTo(this.currentIndex);
		},
	},
	async mounted() {
		let query;
		let data;
		if (!this.slidingPagesSlug) {
			query = {
				query: GET_LAST_POSTS(9, 'where: {categoryName: "novità"}'),
			};
			data = await this.$apollo.query(query);
			this.slides = data.data.posts.nodes;
		} else {
			query = {
				query: GET_LAST_POSTS_BY_QZR_TARGET(this.slidingPagesSlug, "novita"),
			};
			data = await this.$apollo.query(query);
			this.slides = data.data.qzrTargets.nodes[0].posts.nodes;
		}

		this.slides = this.formatCategoriesAndTargets(this.slides);
		this.numberOfPosts =
			window.innerWidth > 768
				? Math.floor(this.slides.length / 3) + 1
				: window.innerWidth > 550
				? Math.floor(this.slides.length / 2) + 1
				: this.slides.length;
	},
};
</script>

<style lang="scss" scoped>
.pagination {
	position: relative;
	margin-top: 1rem;
}
.qzr-title {
	text-align: center;
	padding: 0;
	margin-bottom: $spacing-1;
	display: inline-block;
	width: calc(100% - 96px);
}
.slider {
	padding: $spacing-1 0;
}
.slide {
	position: relative;
	display: inline-block;
}
.swipe {
	width: 100%;
}

.swiper-pagination-bullet {
	border: 2px solid #000;
	background: #fff;
	opacity: 1;
	height: 10px;
	width: 10px;
	margin-right: 4px;
}

.swiper-pagination-bullet.active {
	background: #000;
}
.more {
	text-transform: uppercase;
	color: #000;
	cursor: pointer;
	font-size: $font-size-12;
	float: right;
	line-height: 44px;
}
.more:hover {
	text-decoration: underline;
}
@media only screen and (max-width: 768px) {
	.qzr-title {
		text-align: left;
		max-width: 150px;
	}
}
@media only screen and (max-width: 550px) {
}
</style>
