<template>
	<div class="target-list background-gray">
		<MainColumn>
			<div class="text font-size-20">
				<h2 class="font-size-35">{{ block.attributes.title }}</h2>
				<p v-html="block.attributes.content" />
			</div>
			<div class="buttons">
				<div
					class="button background-all"
					depressed
					v-for="(button, i) in parseAttribute(block.attributes.buttons)"
					:key="i"
					@click="$vuetify.goTo('#' + button.target, { offset: 100 })"
				>
					{{ button.label }}
				</div>
			</div>
			<br class="clear" />
		</MainColumn>
	</div>
</template>

<script>
export default {
	props: ["block", "blocks", "parentBlock"],
	components: {},
	data() {
		return {
			info: "",
		};
	},
	mounted() {},
};
</script>

<style lang="scss" scoped>
.text,
.buttons {
	width: 50%;
	float: left;
}
h2 {
	margin-bottom: 1rem;
}
.target-list {
	padding: $spacing-1 0;
}
.text {
	padding-right: $spacing-0 !important;
}
.buttons {
	padding-left: $spacing-0;
}
.button {
	border-radius: 0;
	color: #fff;
	letter-spacing: unset;
	text-transform: none;
	width: calc(100% - 100px);
	margin-bottom: 1rem;
	font-size: $font-size-16;
	border-radius: 90px;
	box-shadow: 0;
	padding: 1rem;
	cursor: pointer;
}

.button:hover {
	background: #000 !important;
	color: #fff;
	font-weight: bold;
}

.button:nth-child(odd) {
	float: left;
	border-top-left-radius: 0;
}

.button:nth-child(even) {
	float: right;
	border-top-right-radius: 0;
}

.family {
	background: $orange !important;
	border: 0;
}
.youth {
	background: $green !important;
}
.adults {
	background: $red !important;
}
.schools {
	background: $purple !important;
}
@media only screen and (max-width: 768px) {
	.text,
	.buttons {
		width: 100%;
		float: none;
		clear: both;
		display: inline-block;
		padding-left: 0;
		margin-top: 1rem;
	}
	.buttons {
		margin-top: 2.5rem;
	}
}
@media only screen and (max-width: 550px) {
	.button {
		width: 85%;
		font-size: $font-size-14;
	}
}
</style>
