<template>
	<div>
		<component
			v-if="block"
			:class="'color-' + block.attributes.textColor"
			v-bind:is="'h' + block.attributes.level"
			>{{ block.attributes.content }}</component
		>
	</div>
</template>

<style lang="scss" scoped>
h1,
h2,
h3,
h4,
h5,
h6 {
	min-height: 1rem;
	padding-bottom: $spacing-0;
}

h1 {
	font-size: $font-size-55;
}

h2 {
	font-size: $font-size-40;
	line-height: 1.2;
}
h3 {
	font-size: $font-size-25;
}
</style>

<script>
export default {
	name: "CoreHeadingBlock",
	props: ["block"],
};
</script>
