<template>
	<div class="video">
		<youtube
			v-if="
				!block.attributes.providerNameSlug ||
					block.attributes.providerNameSlug === 'youtube'
			"
			width="100%"
			:video-id="getId(block.attributes.url)"
			:player-vars="playerVars"
		></youtube>
	</div>
</template>

<script>
export default {
	props: ["block", "parentBlock"],
	data() {
		return {
			playerVars: {
				autoplay: 0,
			},
		};
	},
	methods: {
		getId(url) {
			return this.$youtube.getIdFromUrl(url);
		},
	},
	computed: {
		player() {
			return this.$refs.youtube.player;
		},
	},
};
</script>
<style lang="scss" scoped>
.video {
	padding-bottom: $spacing-0;
}
</style>
