import Vue from "vue";
import VueRouter from "vue-router";
import VueGtag from "vue-gtag";

Vue.use(VueRouter);

const routes = [
	{ path: "/404", name: "404", component: () => import("../views/404.vue") },
	{
		path: "/",
		alias: ["/contatti", "/preiscrizione"],
		name: "Home",
		component: () => import("../views/Home.vue"),
	},
	{
		path: "/sliding-pages/:pageSlug?",
		name: "SlidingPages",
		component: () => import("../views/SlidingPages.vue"),
	},
	{
		path: "/notizie/:category?",
		alias: [
			"/notizie/:category?/ragazzi",
			"/notizie/:category?/bambini-e-famiglie",
			"/notizie/:category?/adulti",
			"/notizie/:category?/scuole",
		],
		name: "Archives",
		component: () => import("../views/Archives.vue"),
	},
	{
		path: "/notizie/:category/:postSlug?",
		name: "News",
		component: () => import("../views/News.vue"),
	},
	{
		path: "/notizie/:category?/:target?",
		name: "Archives",
		component: () => import("../views/Archives.vue"),
	},

	{
		path: "/:slug/",
		name: "DefaultPage",
		component: () => import("../views/DefaultPage.vue"),
	},
];

const router = new VueRouter({
	routes,
	base: process.env.VUE_APP_BASE_ROUTER,
	mode: "history",
});

Vue.use(
	VueGtag,
	{
		config: { id: "UA-167337791-2" },
	},
	router
);

export default router;
