<template>
	<MainColumn>
		<v-expand-transition>
			<div
				class="openingtime"
				v-if="infoOpeningTime"
				v-html="infoOpeningTime.time"
				@click="updateProperty({ property: 'contactsDialog', value: true })"
			></div>
		</v-expand-transition>
	</MainColumn>
</template>

<script>
export default {
	props: ["block", "blocks", "parentBlock"],
	components: {},
	async mounted() {
		if (!this.infoOpeningTime) {
			let response = await this.$api.get("/wp-json/qzrstudio/v1/openingtime");
			setTimeout(() => {
				this.updateProperty({
					property: "infoOpeningTime",
					value: response.data,
				});
			}, 2500);
		}
	},
};
</script>

<style lang="scss" scoped>
.container {
	z-index: 100;
}
.openingtime span {
	float: left;
	margin-right: 6px;
	font-weight: bold !important;
}

.openingtime p {
	margin-bottom: 0;
}

.openingtime {
	font-size: $font-size-14;
	border-radius: 25px;
	border-bottom-left-radius: 0;
	position: absolute;
	padding: 1rem $spacing-0;
	top: calc(94vh - 90px);
	transform: translateY(-50%);
	background: #fff;

	z-index: 500;
	cursor: pointer;
}

.openingtime a {
	color: #000;
}

@media only screen and (max-width: 1024px) {
	.openingtime {
		top: calc(94vh - 30px);
	}
}

@media only screen and (max-width: 550px) {
	.openingtime {
		width: calc(100%);
	}
}
@media only screen and (max-width: 320px) {
	.openingtime {
		top: calc(100vh - 80px);
	}
}
</style>
