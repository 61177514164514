<template>
	<div class="block-core-columns">
		<div
			v-for="(column, i) in block.innerBlocks"
			:key="i"
			:style="
				'width:' +
					(column.attributes.width
						? column.attributes.width
						: defaultWidth) +
					'%'
			"
		>
			<RenderBlocks
				:blocks="column.innerBlocks"
				:parentBlock="parentBlock"
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: "CoreColumnsBlock",
	props: ["block", "parentBlock"],
	components: {
		RenderBlocks: () => import("@/components/blocks/RenderBlocks.vue")
	},
	data() {
		let remaningSpace = 100;
		let countingUnsets = this.block.innerBlocks.length;
		this.block.innerBlocks.map(function(value) {
			if (value.attributes.width) {
				remaningSpace = remaningSpace - value.attributes.width;
				countingUnsets--;
			}
		});
		return {
			defaultWidth: remaningSpace / countingUnsets
		};
	}
};
</script>

<style lang="scss">
.block-core-columns {
	display: flex;
	margin: 50px 0;
}
.block-core-columns img {
	margin: 0 !important;
}
.block-core-columns > div:last-child {
	padding-left: 25px;
}
@media only screen and (max-width: 768px) {
	.block-core-columns {
		display: inline-block;
		width: 100%;
		margin: 25px 0;
	}
	.block-core-columns > div {
		padding-left: 0;
		width: 100% !important;
	}

	.block-core-columns > div:last-child,
	.block-core-columns div {
		padding-left: 0;
	}
	.block-core-columns > div:last-child {
		margin-top: 25px;
	}
}
</style>
