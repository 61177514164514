<template>
	<MainColumn>
		<div :class="'school-panel background-light-' + slidingPagesSlug">
			<v-row>
				<v-col cols="4">
					<ImageShape
						category="reportages"
						class="image"
						:srcImage="parseAttribute(block.attributes.image).url"
					/>
				</v-col>
				<v-col cols="8">
					<h3 class="font-size-25" v-html="block.attributes.title" />
					<p class="font-size-16" v-html="block.attributes.content" />
					<p class="font-size-14" v-html="block.attributes.label" />
				</v-col>
			</v-row>
		</div>
	</MainColumn>
</template>

<script>
import ImageShape from "@/components/ui/ImageShape.vue";

export default {
	props: ["block", "blocks", "parentBlock"],
	components: { ImageShape },
	data() {
		return {};
	},
};
</script>

<style lang="scss" scoped>
.school-panel {
	margin-bottom: $spacing-0;
}

p {
	color: #000;
}

.image {
	width: 70%;
}
.school-panel {
	padding: $spacing-1;
}
</style>
