<template>
	<div>
		<div :class="'imageWrapper ' + 'imageWrapper-' + type" v-show="show">
			<picture>
				<img
					:alt="'Immagine ' + category"
					:class="{
						['image-' + category]: true,
						blend: blend,
					}"
					:style="'opacity:' + (show ? '1' : '0')"
					@load="show = true"
					sizes="(max-width: 320px) 20w,
            (max-width: 768px) 300px"
					:srcset="srcImage"
				/>
			</picture>
		</div>
		<div v-show="!show" class="placeholder" />

		<svg
			v-if="category === 'novita' && show"
			xmlns="http://www.w3.org/2000/svg"
		>
			<defs>
				<clipPath id="news" clipPathUnits="objectBoundingBox">
					<path
						d="m0.61771 0.0087056a0.112 0.112 0 0 1 0.147 0.061l0.031 0.074a0.112 0.112 0 0 0 0.06 0.061l0.075 0.031a0.112 0.112 0 0 1 0.061 0.147l-0.031 0.075a0.112 0.112 0 0 0 0 0.086l0.031 0.075a0.112 0.112 0 0 1-0.061 0.147l-0.075 0.03a0.112 0.112 0 0 0-0.06 0.06l-0.031 0.075a0.112 0.112 0 0 1-0.147 0.061l-0.075-0.031a0.112 0.112 0 0 0-0.086 0l-0.075 0.031a0.112 0.112 0 0 1-0.147-0.061l-0.03-0.075a0.112 0.112 0 0 0-0.061-0.06l-0.074-0.031a0.112 0.112 0 0 1-0.061-0.147l0.031-0.075a0.112 0.112 0 0 0 0-0.086l-0.031-0.074a0.112 0.112 0 0 1 0.061-0.147l0.074-0.031a0.112 0.112 0 0 0 0.061-0.061l0.031-0.074a0.112 0.112 0 0 1 0.147-0.061l0.075 0.031a0.112 0.112 0 0 0 0.086 0"
					/>
				</clipPath>
			</defs>
		</svg>

		<svg
			v-if="category === 'reportages' && show"
			xmlns="http://www.w3.org/2000/svg"
		>
			<defs>
				<clipPath id="reportage" clipPathUnits="objectBoundingBox">
					<path
						d="M 0.49999999,0 C 0.88461535,0 1,0.5 1,0.5 1,0.5 0.88461535,1 0.49999999,1 0.11538461,1 0,0.5 0,0.5 0,0.5 0.11538461,0 0.49999999,0 Z"
					/>
				</clipPath>
			</defs>
		</svg>
	</div>
</template>

<script>
export default {
	props: {
		category: {
			type: String,
			required: true,
		},
		type: {
			type: String,
			required: false,
		},
		srcImage: {
			type: String,
			required: true,
		},
		imageSizes: {
			type: String,
			require: false,
		},
		blend: {
			type: Boolean,
			require: false,
		},
	},
	data() {
		return {
			show: false,
		};
	},
};
</script>
<style lang="scss" scoped>
img[class^="image-"].blend {
	mix-blend-mode: multiply;
	height: 100%;
	opacity: 0;
}

.imageWrapper.imageWrapper-home {
	width: 40vh;
	margin: auto;
}
.image-novita {
	clip-path: url("#news");
	filter: grayscale(100%);
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	height: 100%;
}
.image-reportages {
	border-radius: 50%;
	filter: grayscale(100%);
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	height: 100%;
}
.image-recensioni {
	position: absolute;
	height: 100%;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	object-fit: contain;
}
.imageWrapper {
	max-width: 100%;
	position: relative;
	padding-top: 100%;
}
.placeholder {
	height: 280px;
}
img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
svg {
	position: absolute;
}
@media only screen and (max-width: 550px) {
}
</style>
