<template>
	<v-dialog
		ref="subscriptionDialog"
		v-model="subscriptionDialogOpen"
		fullscreen
		hide-overlay
		scrollable
		transition="dialog-bottom-transition"
	>
		<div v-if="page" class="modal" @click.self="onClickOutside">
			<MainColumn>
				<v-row>
					<v-col cols="12">
						<h2 class="font-size-100 white--text mb-4">{{ page.title }}</h2>
					</v-col>

					<v-icon class="close-icon" color="white" @click.self="onClickOutside">
						mdi-close
					</v-icon>
				</v-row>
				<div
					class="form"
					:style="{
						'min-height': sent ? 0 : '100vh',
						'margin-top': sent ? '15vh' : 0,
						'padding-bottom': sent ? 0 : '10vh',
					}"
				>
					<v-row
						:style="{
							'background-color': !sent ? colorsObject['all'] : colorsObject['childs'],
						}"
					>
						<v-col
							align-self="center"
							:cols="$vuetify.breakpoint.mobile || sent ? 10 : 6"
							offset="1"
							:class="{
								'white--text': true,
								'font-size-20': !sent,
								'font-size-25': sent,
								infoColumn: true,
							}"
						>
							<div :class="{ 'text-center': sent }">
								<v-icon size="40" color="white" v-if="sent">
									mdi-check
								</v-icon>
								{{
									!sent
										? $t("preSubscriptionHeader")
										: "Grazie, abbiamo ricevuto tutto! Ci vediamo in biblioteca."
								}}
							</div>
							<v-btn name="Scopri" v-if="!sent" depressed @click="onServicesClick()" class="infoButton"
								>Scopri i servizi</v-btn
							>
						</v-col>
						<v-col offset="1" cols="3" align-self="center" v-if="!sent && !$vuetify.breakpoint.mobile">
							<div class="logoCard">
								<Logo class="logo" />
							</div>
						</v-col>
					</v-row>
					<v-slide-y-reverse-transition>
						<div v-if="!sent">
							<v-row v-on:click.stop>
								<v-col>
									<RenderBlocks :blocks="page.blocksJSON" />
								</v-col>
							</v-row>
							<v-row>
								<v-slide-y-reverse-transition>
									<v-col offset="1" cols="10" v-if="isSubmitButtonVisible">
										<v-checkbox v-model="gdpr" :label="gdprText"></v-checkbox>
									</v-col>
								</v-slide-y-reverse-transition>
								<v-slide-y-reverse-transition>
									<v-col offset="1" cols="10" v-if="isSubmitButtonVisible">
										<v-checkbox
											v-model="digitalResources"
											:label="digitalResourcesText"
										></v-checkbox>
									</v-col>
								</v-slide-y-reverse-transition>
							</v-row>
							<v-row justify="center">
								<v-slide-y-reverse-transition>
									<v-btn
										v-if="isSubmitButtonVisible"
										class="button white--text"
										:color="colorsObject['all']"
										:disabled="!isSubscriptionFormValid"
										@click="onSubmitForm"
										text
										>{{ $t("subscribe") }}</v-btn
									>
								</v-slide-y-reverse-transition>
							</v-row>
						</div>
					</v-slide-y-reverse-transition>
				</div>
			</MainColumn>
		</div>
	</v-dialog>
</template>
<script>
import { GET_PAGE } from "@/queries/Pages.js";
import Logo from "@/components/ui/Logo";
import RenderBlocks from "@/components/blocks/RenderBlocks";
export default {
	components: {
		Logo,
		RenderBlocks,
	},
	props: ["fromHeader"],
	data() {
		return {
			gdpr: false,
			gdprText:
				"Trattamento dei dati personali per fini di gestione del servizio secondo il Regolamento (UE) 679/2016 e i Decreti legislativi 196/2003 e 101/2018.",
			digitalResources: false,
			digitalResourcesText:
				"Desidero essere iscritto al servizio di Biblioteca Digitale MLOL per il prestito e-book e risorse on-line",
			page: null,
			sent: false,
		};
	},
	computed: {
		isSubscriptionFormValid() {
			let array = Object.keys(this.subscriptionFormList);
			return array.every((formSlug) => this.subscriptionFormList[formSlug]) && this.gdpr;
		},
		subscriptionDialogOpen: {
			get: function() {
				return this.subscriptionDialog;
			},
			set: function(newValue) {
				this.updateProperty({
					property: "subscriptionDialog",
					value: newValue,
				});
			},
		},
	},
	async mounted() {
		let query = {
			query: GET_PAGE("preiscrizione"),
		};
		let data = await this.$apollo.query(query);
		this.page = data.data.pages.nodes[0];
	},
	methods: {
		calculateOffset() {
			if (this.$vuetify.breakpoint.mobile || !this.sent) {
				return 1;
			}
			return 2;
		},
		onClickOutside() {
			if (this.fromHeader) {
				this.$router.go(-1);
			} else {
				history.pushState({}, null, process.env.VUE_APP_BASE_ROUTER);
			}
			this.updateProperty({
				property: "currentRoute",
				value: {
					path: "",
				},
			});
			this.updateProperty({
				property: "subscriptionDialog",
				value: false,
			});
			this.sent = false;
		},
		onServicesClick() {
			this.onClickOutside();
			this.$router.push({ path: "/spazi-e-servizi" });
		},
		async onSubmitForm() {
			await this.$api.post("/wp-json/qzrstudio/v1/mail/new_subscriber", {
				to: this.emailForSubscribe,
				fields: this.infoSubscription,
				digitalResources: this.digitalResources,
			});
			this.sent = true;
		},
	},
};
</script>
<style lang="scss" scoped>
#app .button {
	border-radius: 90px;
	border-top-left-radius: 0;
	background: $blue;
	font-size: $font-size-14;
	padding: $spacing-0 50px;
	margin-top: $spacing-0;
}
#app .button[disabled="disabled"] {
	background: #f1f1f1;
}
.form {
	margin-bottom: 10vh;
	background: white;
	transition: all 1s ease;
}
.formHeader {
	background-color: $blue;
}
.infoColumn {
	padding: $spacing-0 0;
	transition: all 0.8s;
}
.infoButton {
	padding: 0 0;
	color: white;
	border: 2px solid #fff;
	border-radius: 50px 0 50px 50px;
	background: $blue !important;
	margin: $spacing-0 0;
	font-size: $font-size-12;
}
.logo {
	display: block;
	height: 80%;
	margin: 0 auto;
	padding: 20px 0;
}
.logoCard {
	background-color: $red;
	border-radius: 15px;
	border-top-left-radius: 0;
	transform: rotate(-10deg);
}
.logoCard:hover {
	animation: shake 1s;
	animation-iteration-count: infinite;
}
.modal {
	background: rgba(0, 0, 0, 0.8) !important;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	min-height: 100%;
	padding-top: 100px;
}
.transformCard {
	transform: rotate(-10deg);
}
@keyframes shake {
	0% {
		transform: translate(1px, 1px) rotate(-10deg);
	}
	10% {
		transform: translate(-1px, -2px) rotate(-11deg);
	}
	20% {
		transform: translate(-3px, 0px) rotate(-9deg);
	}
	30% {
		transform: translate(3px, 2px) rotate(-10deg);
	}
	40% {
		transform: translate(-3px, 0px) rotate(-9deg);
	}
	50% {
		transform: translate(3px, 2px) rotate(-10deg);
	}
	100% {
		transform: rotate(-10deg);
	}
}
.close-icon {
	position: absolute;
	top: 90px;
	right: 0;
	z-index: 999;
}
@media only screen and (max-width: 1024px) {
	.close-icon {
		top: 74px;
	}
}

@media only screen and (max-width: 768px) {
	.close-icon {
		top: 44px;
	}
}
@media only screen and (max-width: 550px) {
	.close-icon {
		top: 28px;
	}
}
@media only screen and (max-width: 480px) {
	.close-icon {
		top: 12px;
	}
}
</style>
