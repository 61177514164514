import gql from "graphql-tag";

function GET_PROJECTS_BY_TARGET(slug) {
	return gql`
	query GET_PROJECTS_BY_TARGET {
		qzrTargets(where: { slug: "${slug}" }) {
			nodes {
				projects {
					nodes {
						seo {
							metaDesc
							metaKeywords
							title
							opengraphTitle
							opengraphSiteName
							opengraphPublisher
							opengraphType
							opengraphUrl
							opengraphPublishedTime
							opengraphModifiedTime
							opengraphImage {
							  id
							  uri
							  sourceUrl
							}
						  }
						id
						title
						blocks {
				...on LazyblockProjectBlock {
								dynamicContent
								originalContent
								attributes {
									content
									subtitle
									url
								}
							}
						}
					}
				}
			}
	} }`;
}

export { GET_PROJECTS_BY_TARGET };
