export const formatCategoriesAndTargets = {
	methods: {
		formatCategoriesAndTargets(data) {
			if (!Array.isArray(data)) {
				data = [data];
			}
			data.map((element) => {

				if(element.qzrTargets && element.qzrTargets.nodes) {

					let countTargets = element.qzrTargets.nodes.length;

					switch (countTargets) {
						case 0:
							element.target = false;
							break;

						case 1:
							element.target = {
								slug: element.qzrTargets.nodes[0].slug,
								name: element.qzrTargets.nodes[0].name,
							};
							break;

						default:
							element.target = { slug: "all", name: "Per tutti" };
							break;
					}
				}
				else {
					element.target = false;
				}

				// Parsing categories
				let categories = [];
				if (element.categories && element.categories.nodes) {
					element.categories.nodes.map((category) => {
						if (category.parentDatabaseId)
							element.subCategory = {
								name: category.name,
								slug: category.slug,
							};
						else {
							element.mainCategory = {
								name: category.name,
								slug: category.slug,
							};
						}

						categories.push({
							name: category.name,
							slug: category.slug,
							hasParent: category.parentDatabaseId ? true : false,
						});
					});
				}
				element.categories = categories;

				// Moving parent category before all
				element.categories.sort((element, nextElement) => {
					return element.hasParent - nextElement.hasParent;
				});

				if (element.blocks && element.blocks[0].name === "core/paragraph") {
					element.blocks.shift();
				}

				for (let i in element.blocks) {
					let block = element.blocks[i];
					if (block.attributes && block.attributes.fields) {
						element.fields = JSON.parse(block.attributes.fields);
					} else {
						element.fields = [];
					}
				}

				if (element.subCategory) {
					switch (element.subCategory.slug) {
						case "books":
							element.author = element.fields.author_books;
							break;

						case "movies":
							element.author = element.fields.direction;
							break;

						case "music":
							element.author = element.fields.author_music;
							break;
					}
				}
			});
			data = data.filter((element) => {
				return element.target;
			});
			return data;
		},
	},
};
