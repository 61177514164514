<template>
	<a
		:href="href"
		:width="width"
		:border="border"
		:class="font_size + ' ' + margin"
		>{{ value }}</a
	>
</template>

<style lang="scss" scoped>
a {
	color: #fff !important;
	padding: 6px $spacing-0;
	text-transform: uppercase;
	border-radius: 90px;
	border-top-right-radius: 0;
	border: 2px solid #fff;
	text-decoration: none;
	display: inline-block;
	transition: 0.3s all;
	font-size: 12px;
}

a[border="black"] {
	color: #000 !important;
	border-color: #000;
}
a[border="black"]:hover {
	background: #000;
	color: #fff !important;
	border-color: #000;
}
a[border="no-border"] {
	border: none;
	border-radius: 0;
	border-bottom: 1px solid transparent;
}

a[width="full"] {
	width: 100%;
	text-align: center;
}

a:hover {
	color: #000 !important;
	background-color: #fff;
}

a[border="no-border"]:hover {
	border-bottom: 1px solid #fff;
	background: transparent;
	color: #fff;
}
</style>

<script>
export default {
	name: "Button",
	props: ["value", "border", "width", "font_size", "href", "margin"]
};
</script>
