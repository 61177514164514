<template>
	<div :class="'cards background-light-' + slidingPagesSlug">
		<MainColumn>
			<v-row>
				<v-col cols="4" class="card-text">
					<h2 class="font-size-35">{{ block.attributes.title }}</h2>
					<div
						class="content font-size-20"
						v-html="block.attributes.content"
					></div>
				</v-col>
				<v-col cols="8" class="sliding-cards">
					<swiper
						v-if="slides.length"
						class="slider"
						ref="swiper"
						:options="swiperOption"
						@slideChange="onSlideChange"
					>
						<swiper-slide v-for="(slide, i) in slides" :key="i">
							<div :class="`card background-${slidingPagesSlug}`">
								<div class="title-slide font-size-25">{{ slide.title }}</div>
								<div
									class="content-slide font-size-16"
									v-html="slide.content"
								></div>
								<v-btn
									text
									:class="'button color-' + slidingPagesSlug"
									v-if="slide.url"
									@click="openLinkInNewTab(slide.url)"
									:name="slidingPagesSlug"
									>Scopri</v-btn
								>
							</div>
						</swiper-slide>
					</swiper>
					<PaginationSlideshow
						slot="pagination"
						:currentIndex="currentIndex + 1"
						:numberOfPosts="numberOfPosts"
						:target="'light-' + slidingPagesSlug"
						class="pagination"
						@onPaginationClick="onPaginationClick"
					></PaginationSlideshow>
				</v-col>
			</v-row>
		</MainColumn>
	</div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import PaginationSlideshow from "@/components/ui/PaginationSlideshow.vue";

export default {
	name: "lazyblock_last_pages",
	props: ["block", "parentBlock"],
	data() {
		return {
			slides: [],
			currentIndex: 0,
			numberOfPosts: 0,
			swiperOption: {
				autoplay: {
					delay: 5000,
				},
				breakpoints: {
					550: {
						slidesPerView: 2,
						spaceBetween: 40,
					},
				},
				centeredSlides: false,
				slidesPerView: 1,
				spaceBetween: 40,
			},
		};
	},
	components: {
		Swiper,
		SwiperSlide,
		PaginationSlideshow,
	},
	mounted() {
		this.slides = this.parseAttribute(this.block.attributes.cards);
		this.numberOfPosts =
			window.innerWidth > 550 ? this.slides.length - 1 : this.slides.length;
	},
	computed: {
		swiper() {
			return this.$refs.swiper.$swiper;
		},
	},
	methods: {
		onSlideChange() {
			this.currentIndex = this.swiper.activeIndex;
		},
		onPaginationClick(index) {
			this.currentIndex = index;
			this.swiper.slideTo(this.currentIndex);
		},
	},
};
</script>

<style lang="scss" scoped>
.pagination {
	margin-top: $spacing-0;
	position: relative;
}
.button {
	background: #fff;
	border-radius: 90px;
	font-size: $font-size-12 !important;
	border-top-left-radius: 0;
	margin-top: $spacing-0;
}
.card >>> br {
	display: inline-block !important;
	height: 1rem !important;
	width: 100% !important;
	position: relative !important;
	content: "" !important;
}

.card {
	border-radius: 40px;
	border-top-right-radius: 0;
}

.cards {
	padding: $spacing-1;
}

.content {
	font-size: $font-size-16;
	color: #000;
}

h2 {
	font-size: $font-size-35;
	margin-bottom: $spacing-0;
	color: #000;
}

.card {
	padding: $spacing-0;
}

.title-slide {
	font-weight: bold;
	margin-bottom: $spacing-0;
}

.background-light-childs {
	background: $light-green;
}
.background-light-schools {
	background: $light-purple;
}
.background-light-adults {
	background: $light-red;
}
.background-light-family {
	background: $light-orange;
}
#app .sliding-cards {
	padding-left: $spacing-0;
}
.slider {
	position: relative;
	max-width: 100%;
}
@media only screen and (max-width: 1024px) {
	.row {
		display: inline-block;
		width: 100%;
	}

	.col {
		width: 100%;
		max-width: 100%;
		display: inline-block;
	}

	.content {
		margin-bottom: $spacing-0;
	}
}
@media only screen and (max-width: 768px) {
	.cards {
		padding: $spacing-1 $spacing-0;
	}
	#app .sliding-cards {
		padding-left: 0;
	}
}
</style>
