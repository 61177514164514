<template>
	<MainColumn>
		<div class="faqs">
			<v-row>
				<h3 class="font-size-35">
					{{ block.attributes.title }}
				</h3>

				<p class="font-size-16" v-html="block.attributes.content"></p>

				<v-expansion-panels flat>
					<v-expansion-panel
						class="faq"
						v-for="(faq, i) in parseAttribute(block.attributes.faqs)"
						:key="i"
						@click="scrollToPanel(i)"
						:id="'faq-' + _uid + '-' + i"
					>
						<v-expansion-panel-header class="faq-header">
							<template v-slot:actions>
								<v-icon color="#000">
									mdi-plus
								</v-icon>
							</template>
							<div class="name">
								{{ faq.ask }}
							</div>
							<div class="separator"></div>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<div v-html="faq.answer" class="font-weight-regular" />
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-row>
		</div>
	</MainColumn>
</template>

<script>
import MainColumn from "@/components/ui/MainColumn";

export default {
	props: ["block", "blocks", "parentBlock"],
	components: { MainColumn },
	data() {
		return {
			projects: [],
		};
	},
	methods: {
		scrollToPanel(i) {
			setTimeout(
				function() {
					this.$vuetify.goTo("#faq-" + this._uid + "-" + i, { offset: 70 });
				}.bind(this),
				300
			);
		},
	},
};
</script>

<style lang="scss" scoped>
.subtitle {
	font-size: $font-size-25;
	margin-bottom: $spacing-0;
}

.button {
	margin: $spacing-0 0;
	border-radius: 90px;
	border-bottom-left-radius: 0;
	padding: 0 $spacing-1;
}

h3 {
	margin-bottom: $spacing-0;
	text-align: center;
	width: 100%;
}

.faqs {
	background: #fff;
	padding: $spacing-1;
	padding-left: calc(4rem + 25px);
	position: relative;
}

.faq {
	background: transparent !important;
	box-shadow: none !important;
	border: none !important;
	font-weight: bold;
	padding-left: 0 !important;
	padding-bottom: 8px;
}
.content {
	padding: $spacing-1;
}

.faq-header {
	display: inline-flex;
	color: #000;
}
.faq-header .name {
	margin-right: 10px;
	min-width: auto;
	display: inline-block;
}
.faq .content {
	font-weight: normal;
}
.faq .content a {
	margin-bottom: 1rem;
	display: inline-block;
}
.separator {
	height: 2px;
	flex: 100 !important;
	display: inline-block;
}

.v-item--active {
	background-color: $gray !important;
	box-shadow: 17px 17px 35px -5px rgba(0, 0, 0, 0.2) !important;
	border-radius: 15px !important;
}

.separator {
	background: #000;
}

@media only screen and (max-width: 768px) {
	h3 {
		text-align: left;
	}
	.name {
		line-height: 1.5;
	}
	.row {
		display: inline-block;
		width: 100%;
	}
	.faqs {
		padding: $spacing-0;
	}
	.faq {
		width: 100%;
		max-width: 100%;
		margin: 0;
	}
}
</style>
