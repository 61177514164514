<template>
	<div class="pattern-shapes">
		<MainColumn style="min-height: 100vh" v-if="!$vuetify.breakpoint.mobile">
			<div v-show="currentPattern == 1" class="pattern pattern-1">
				<Shape type="dots" width="4" height="4" right="0" bottom="4" :target="currentColors[0]" />
				<Shape type="circle" width="10" height="10" left="18" top="2" :target="currentColors[1]" />
				<Shape class="shape-3" type="circle" width="1" height="1" left="0" top="5" :target="currentColors[2]" />
			</div>
			<div v-show="currentPattern == 2" class="pattern pattern-2">
				<Shape class="shape-1" type="dots" width="3" height="3" left="0" top="1" :target="currentColors[0]" />
				<Shape type="circle" width="6" height="6" right="-2" top="4" :target="currentColors[1]" />
				<Shape type="circle" width="2" height="2" right="10" bottom="8" :target="currentColors[2]" />
			</div>
			<div v-show="currentPattern == 3" class="pattern pattern-3">
				<Shape type="circle" width="7" height="7" left="-2" top="0" :target="currentColors[0]" />
				<Shape type="circle" width="2" height="2" right="5" top="5" :target="currentColors[1]" />
			</div>
		</MainColumn>
		<MainColumn style="min-height: 100vh" v-else>
			<div class="pattern pattern-3">
				<Shape type="circle" width="2" height="2" left="0" top="3" :target="currentColors[0]" />
				<Shape type="circle" width="1" height="1" right="0" top="6" :target="currentColors[1]" /></div
		></MainColumn>
	</div>
</template>

<script>
import MainColumn from "@/components/ui/MainColumn.vue";
import Shape from "@/components/ui/Shape.vue";
export default {
	components: {
		MainColumn,
		Shape,
	},
	props: {
		backgroundTarget: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			currentPattern: 0,
			currentColors: [],
			patternsNumber: 3,
		};
	},
	watch: {
		backgroundTarget() {
			this.calculateColors();
		},
	},
	mounted() {
		this.changePattern();
	},
	methods: {
		changePattern() {
			this.calculateColors();
			let newPattern = Math.floor(Math.random() * this.patternsNumber) + 1;
			while (newPattern === this.currentPattern) {
				newPattern = Math.floor(Math.random() * this.patternsNumber) + 1;
			}
			this.currentPattern = newPattern;
		},
		calculateColors() {
			this.currentColors = [];
			let keys = Object.keys(this.colorsObject);
			if (this.backgroundTarget) {
				let targetIndex = keys.indexOf(this.backgroundTarget);
				keys.splice(targetIndex, 1);
			}
			for (let i = 0; i < this.patternsNumber; i++) {
				let selectedIndex = (keys.length * Math.random()) << 0;
				this.currentColors.push(keys[selectedIndex]);
				keys.splice(selectedIndex, 1);
			}
		},
	},
};
</script>

<style lang="scss">
.pattern-shapes {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	overflow: hidden;
	height: 100vh;
	right: 0;
	max-width: 100vw;
}
</style>
