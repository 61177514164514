<template>
	<div class="block-quote">
		<v-row>
			<v-col v-if="!$vuetify.breakpoint.mobile" cols="1">
				<v-icon class="icon" size="30" :color="colorsObject['all']"
					>mdi-asterisk</v-icon
				>
			</v-col>
			<v-col :cols="$vuetify.breakpoint.mobile ? 12 : 10">
				<div
					class="color-all font-size-20"
					v-html="block.attributes.value"
				></div>
			</v-col>
		</v-row>
		<v-row v-if="block.attributes.citation">
			<v-col
				:offset="$vuetify.breakpoint.mobile ? 0 : 1"
				:cols="$vuetify.breakpoint.mobile ? 12 : 11"
			>
				<div
					class="color-all font-size-16 citation"
					v-html="'- ' + block.attributes.citation"
				></div>
			</v-col>
		</v-row>
	</div>
</template>

<style lang="scss" scoped></style>

<script>
export default {
	name: "core_quote",
	props: ["block"],
	mounted() {}
};
</script>

<style lang="scss" scoped>
.block-quote {
	padding-bottom: $spacing-0;
	@media only screen and (max-width: 768px) {
		padding-bottom: $spacing-0;
	}
}
.citation {
	padding-top: $spacing-0;
}
</style>
