<template>
	<div
		class="main-column"
		:style="{ width: calculateWidthColumn(mainColumnWidth) }"
	>
		<slot />
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#app .main-column {
	position: relative;
	margin: auto;
	max-width: 1200px;
	min-width: 960px;
}

@media only screen and (max-width: 1080px) {
	#app .main-column {
		min-width: auto;
		max-width: 90%;
		width: 90%;
	}
}
</style>
