<template>
	<img :src="block.attributes.url" />
</template>

<style lang="scss" scoped>
img {
	max-width: 100%;
	width: 100%;
	margin: $spacing-0 0;
}
</style>

<script>
export default {
	name: "core_image",
	props: ["block"]
};
</script>
