<template>
	<p
		v-if="block.attributes.content && this.block.attributes.content != ''"
		:class="{
			['color-' + block.attributes.textColor]: true,
			['font-size-' + block.attributes.fontSize]: block.attributes.fontSize,
			'font-size-16': !block.attributes.fontSize,
		}"
		v-html="block.attributes.content"
	></p>
</template>

<style lang="scss" scoped>
	.font-size-big {
		font-size: $font-size-20;
		padding-bottom: 2rem;
		padding-top: 0.5rem;
	}
	p {
		padding-bottom: 1rem;
	}

	.font-size-caption {
		text-transform: uppercase;
		color: #999;
		font-size: $font-size-14;
	}
</style>

<script>
	export default {
		name: 'core_paragraph',
		props: ['block'],
	};
</script>
