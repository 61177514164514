<template>
	<v-slide-y-reverse-transition>
		<div
			v-if="!cookies"
			id="cookies-policy"
			class="col-padding row-padding"
			@click="createCookie('acceptCookies', 'acceptCookies', 7)"
		>
			<MainColumn>
				Questo sito raccoglie dati statistici anonimi sulla navigazione. Sono
				presenti cookie installati da terze parti. Continuando a navigare su
				questo sito, accetti il servizio e gli stessi cookie.<span
					@click="createCookie('acceptCookies', 'acceptCookies', 7)"
					id="cookies-policy-btn"
					>OK</span
				>
			</MainColumn>
		</div>
	</v-slide-y-reverse-transition>
</template>

<script>
import MainColumn from "@/components/ui/MainColumn.vue";

export default {
	components: {
		MainColumn,
	},
	data() {
		return {
			cookies: false,
		};
	},
	methods: {
		createCookie(name, value, days) {
			let expires;
			let date;
			if (days) {
				date = new Date();
				date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
				expires = "; expires=" + date.toGMTString();
			} else expires = "";

			document.cookie = name + "=" + value + expires + "; path=/";
			this.cookies = true;
		},
		readCookie(name) {
			let ca = document.cookie.split(";");
			let result = 0;
			result = ca.filter((cookie) => {
				return cookie.includes(name);
			});
			return result.length;
		},
	},
	mounted() {
		this.cookies = this.readCookie("acceptCookies");
	},
};
</script>

<style lang="scss" scoped>
#cookies-policy a {
	color: #fff;
}

#cookies-policy a:hover {
	color: #497dd6;
}

#cookies-policy {
	cursor: pointer;
	padding: 30px 0;
	font-size: $font-size-12;
	position: fixed;
	bottom: 0;
	z-index: 10000;
	background: rgba(0, 0, 0, 0.8);
	width: 100%;
	color: #fff;
}
#cookies-policy-btn {
	font-weight: bold;
	color: #fff;
	text-decoration: underline;
	padding: 0px 10px;
	text-align: center;
	cursor: pointer;
}
#cookies-policy-btn:hover {
	color: #497dd6;
}
</style>
