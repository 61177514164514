<template>
	<div
		class="shape"
		:style="{
			'border-color': colorsObject[target],
			top: top ? calculateSpace(top) : 'auto',
			bottom: bottom ? calculateSpace(bottom) : 'auto',
			left: left ? calculateSpace(left) : 'auto',
			right: right ? calculateSpace(right) : 'auto',
			width: calculateSpace(width),
			height: calculateSpace(height),
		}"
	>
		<div
			v-if="type == 'circle'"
			:style="{
				width: calculateSpace(width),
				height: calculateSpace(height),
				'border-width':
					($vuetify.breakpoint.mobile ? columnWidth / 2 : columnWidth) + 'px',
				'border-color': colorsObject[target],
			}"
			class="circle"
		></div>

		<div
			v-if="type == 'dots'"
			:style="{
				width: calculateSpace(width),
				height: calculateSpace(height),
			}"
			class="dots"
		>
			<div
				:style="{
					'background-color': colorsObject[target],
					width: columnWidth + 'px',
					height: columnWidth + 'px',
					left: 0,
					top: 0,
				}"
			></div>
			<div
				:style="{
					'background-color': colorsObject[target],
					width: this.columnWidth + 'px',
					height: this.columnWidth + 'px',
					right: 0,
					top: 0,
				}"
			></div>
			<div
				:style="{
					'background-color': colorsObject[target],
					width: this.columnWidth + 'px',
					height: this.columnWidth + 'px',
					bottom: 0,
				}"
			></div>
			<div
				:style="{
					'background-color': colorsObject[target],
					width: this.columnWidth + 'px',
					height: this.columnWidth + 'px',
					bottom: 0,
					right: 0,
				}"
			></div>
		</div>

		<div
			v-if="type == 'cross'"
			:style="{
				width: calculateSpace(width),
				height: calculateSpace(height),
			}"
			class="cross"
		>
			<div
				:style="{
					'background-color': colorsObject[target],
					width: columnWidth + 'px',
					height: columnWidth + 'px',
					left: '50%',
					transform: 'translateX(-50%)',
					top: 0,
				}"
			></div>
			<div
				:style="{
					'background-color': colorsObject[target],
					width: this.columnWidth + 'px',
					height: this.columnWidth + 'px',
					right: 0,
					top: '50%',
					transform: 'translateY(-50%)',
				}"
			></div>
			<div
				:style="{
					'background-color': colorsObject[target],
					width: this.columnWidth + 'px',
					height: this.columnWidth + 'px',
					left: '50%',
					transform: 'translateX(-50%)',
					bottom: 0,
				}"
			></div>
			<div
				:style="{
					'background-color': colorsObject[target],
					width: this.columnWidth + 'px',
					height: this.columnWidth + 'px',
					left: 0,
					top: '50%',
					transform: 'translateY(-50%)',
				}"
			></div>
		</div>

		<div
			v-if="type == 'rect'"
			:style="{
				'border-width':
					($vuetify.breakpoint.mobile ? columnWidth / 2 : columnWidth) + 'px',
				'border-color': colorsObject[target],
				width: calculateSpace(width),
				height: calculateSpace(height),
			}"
			class="rect"
		></div>
	</div>
</template>

<script>
export default {
	props: [
		"type",
		"width",
		"height",
		"left",
		"right",
		"top",
		"bottom",
		"target",
	],
	components: {},
	data() {
		return {
			info: "",
		};
	},
	mounted() {},
};
</script>

<style lang="scss" scoped>
.shape {
	position: absolute;
	z-index: 0;
}

.circle {
	border-radius: 50%;
	border: solid;
	display: inline-block;
}

.dots,
.cross {
	display: inline-block;
	position: relative;
}

.dots div,
.cross div {
	position: absolute;
}

.rect {
	display: inline-block;
	border: solid;
}
</style>
