<template>
	<MainColumn>
		<div class="slider">
			<div class="header">
				<h2 class="qzr-title font-size-35">
					Questo mese ti proponiamo
				</h2>

				<div
					class="more"
					@click="onArchivesClick('recensioni', block.attributes.target)"
				>
					Scoprili tutti
				</div>
			</div>
			<swiper
				class="swipe"
				ref="swiper"
				:options="swiperOption"
				@clickSlide="handleSwiperSlideClick"
				@slideChange="onSlideChange"
			>
				<swiper-slide
					v-for="(slide, i) in slides"
					:key="i"
					:class="'slide background-' + slide.target"
				>
					<ArticleCard
						:mainCategory="slide.mainCategory"
						:subCategory="slide.subCategory ? slide.subCategory : 'libri'"
						:target="slide.target"
						:image="
							slide.featuredImage ? slide.featuredImage.node.sourceUrl : ''
						"
						:title="slide.title"
						:subtitle="slide.author"
						:isSubheaderVisible="true"
						:bottomAlign="true"
					/>
				</swiper-slide>
			</swiper>
			<PaginationSlideshow
				slot="pagination"
				:currentIndex="currentIndex + 1"
				:numberOfPosts="numberOfPosts"
				:target="'white'"
				class="pagination"
				@onPaginationClick="onPaginationClick"
			></PaginationSlideshow>
		</div>
	</MainColumn>
</template>

<script>
import ArticleCard from "@/components/ui/ArticleCard.vue";
import PaginationSlideshow from "@/components/ui/PaginationSlideshow.vue";
import { formatCategoriesAndTargets } from "@/mixins/formatCategoriesAndTargets";
import { GET_LAST_POSTS, GET_POSTS_BY_QZR_TARGET } from "@/queries/Posts.js";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";

export default {
	mixins: [formatCategoriesAndTargets],
	props: ["block", "blocks", "parentBlock"],
	components: {
		ArticleCard,
		PaginationSlideshow,
		Swiper,
		SwiperSlide,
	},
	data() {
		return {
			currentIndex: 0,
			fields: [],
			slides: [],
			numberOfPosts: 0,
			swiperOption: {
				slidesPerView: 1,
				spaceBetween: 25,
				centeredSlides: false,
				breakpoints: {
					550: {
						slidesPerView: 2,
					},
					768: {
						slidesPerView: 3,
						spaceBetween: 40,
					},
				},
			},
		};
	},
	computed: {
		swiper() {
			return this.$refs.swiper.$swiper;
		},
	},
	async mounted() {
		let query;
		let data;
		if (this.block.attributes.target == "all") {
			query = {
				query: GET_LAST_POSTS(9, 'where: {categoryName: "recensioni"}'),
			};
			data = await this.$apollo.query(query);
			this.slides = data.data.posts.nodes;
		} else {
			query = {
				query: GET_POSTS_BY_QZR_TARGET(
					this.block.attributes.target,
					"recensioni"
				),
			};
			data = await this.$apollo.query(query);
			this.slides = data.data.qzrTargets.nodes[0].posts.nodes;
		}
		this.slides = this.formatCategoriesAndTargets(this.slides);

		this.numberOfPosts =
			window.innerWidth > 768
				? Math.ceil(this.slides.length / 3)
				: window.innerWidth > 550
				? Math.ceil(this.slides.length / 2)
				: this.slides.length;
	},
	methods: {
		onSlideChange() {
			this.currentIndex =
				window.innerWidth > 768
					? Math.floor((this.swiper.activeIndex + 1) / 3)
					: window.innerWidth > 550
					? Math.floor((this.swiper.activeIndex + 1) / 2)
					: this.swiper.activeIndex;
		},
		onPaginationClick(index) {
			this.currentIndex =
				window.innerWidth > 768
					? index * 3 + 1
					: window.innerWidth > 550
					? index * 2 + 1
					: index;
			this.swiper.slideTo(this.currentIndex);
		},
	},
};
</script>

<style lang="scss" scoped>
.swiper-pagination {
	width: 100%;
	display: inline-block;
	position: relative;
}

.qzr-title {
	text-align: center;
	padding: 0;
	margin-bottom: $spacing-1;
	display: inline-block;
	width: calc(100% - 96px);
}
.slider {
	padding: $spacing-1 0;
}

.pagination {
	margin-top: $spacing-0;
}

.slide {
	position: relative;
	display: inline-block;
}
.swipe {
	width: 100%;
}

.more {
	text-transform: uppercase;
	color: #000;
	cursor: pointer;
	font-size: $font-size-12;
	float: right;
	line-height: 44px;
}
.more:hover {
	text-decoration: underline;
}

@media only screen and (max-width: 768px) {
	.qzr-title {
		text-align: left;
	}
	.bottom-align .image {
		max-height: 300px;
		height: 300px;
	}
	h3 {
		font-size: 14px;
	}
}
</style>
