var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
		'single-card': true,
		'background-all': _vm.block.attributes.target == 'all',
		'background-adults': _vm.block.attributes.target == 'adults',
		'background-childs': _vm.block.attributes.target == 'childs',
		'background-family': _vm.block.attributes.target == 'family',
		card: true
	},attrs:{"id":_vm.block.attributes.name}},[(_vm.block.attributes.image && _vm.parseAttribute(_vm.block.attributes.image).url)?_c('img',{attrs:{"src":_vm.parseAttribute(_vm.block.attributes.image).url}}):_vm._e(),_c('h3',{staticClass:"font-size-35"},[_vm._v(_vm._s(_vm.block.attributes.title))]),_c('swiper',{ref:"swiper",staticClass:"swipe",attrs:{"options":_vm.swiperOption},on:{"slideChange":_vm.onSlideChange}},_vm._l((_vm.contents),function(content,i){return _c('swiper-slide',{key:i,staticClass:"slide"},[_c('div',{staticClass:"content"},[(content.title_card)?_c('h4',{staticClass:"font-size-25"},[_vm._v(" "+_vm._s(content.title_card)+" ")]):_vm._e(),_c('div',{domProps:{"innerHTML":_vm._s(content.content_card)}})])])}),1),_c('PaginationSlideshow',{staticClass:"pagination",attrs:{"target":_vm.block.attributes.target,"currentIndex":_vm.currentIndex + 1,"numberOfPosts":_vm.numberOfPosts},on:{"onPaginationClick":_vm.onPaginationClick}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }