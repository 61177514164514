<template>
	<div :class="'projects projects-' + slidingPagesSlug">
		<Background
			class="background"
			:target="'light-' + slidingPagesSlug"
			diameter="13"
			color="#fff"
		>
			<MainColumn :class="'background-' + slidingPagesSlug">
				<div class="content">
					<v-row>
						<v-col cols="4">
							<h3 class="font-size-40">
								{{ block.attributes.title }}
							</h3>
						</v-col>
						<v-col offset="1" cols="7">
							<p
								class="font-size-16 description"
								v-html="block.attributes.content"
							></p>

							<v-expansion-panels v-model="panel" flat>
								<v-expansion-panel
									@click="scrollToPanel(i)"
									class="project"
									v-for="(project, i) in projects"
									:key="i"
									:id="'project-' + _uid + '-' + i"
								>
									<v-expansion-panel-header class="project-header ">
										<template v-slot:actions>
											<v-icon :color="getIconColor(i, slidingPagesSlug)">
												mdi-plus
											</v-icon>
										</template>
										<div class="name">
											{{ project.title }}
										</div>
										<div class="separator"></div>
									</v-expansion-panel-header>
									<v-expansion-panel-content>
										<div
											v-html="project.subtitle"
											class="subtitle font-size-20"
										/>
										<div v-html="project.content" class="font-weight-regular" />
										<v-btn
											:class="`button background-${slidingPagesSlug}`"
											v-if="project.url"
											@click="openLinkInNewTab(project.url)"
											text
											name="Scopri di più"
										>
											Scopri di più</v-btn
										>
									</v-expansion-panel-content>
								</v-expansion-panel>
							</v-expansion-panels>
						</v-col>
					</v-row>
				</div>
			</MainColumn>
		</Background>
	</div>
</template>

<script>
import { GET_PROJECTS_BY_TARGET } from "@/queries/Projects.js";
import Background from "@/components/ui/Background.vue";

export default {
	props: ["block", "blocks", "parentBlock"],
	components: { Background },
	data() {
		return {
			panel: null,
			projects: [],
		};
	},
	async mounted() {
		let query = {
			query: GET_PROJECTS_BY_TARGET(this.slidingPagesSlug),
		};
		let data = await this.$apollo.query(query);
		this.projects = data.data.qzrTargets.nodes[0].projects.nodes;
		this.projects.map((project) => {
			project.status = false;
			project.content = project.blocks[0].attributes.content;
			project.subtitle = project.blocks[0].attributes.subtitle;
			project.url = project.blocks[0].attributes.url;
		});
	},
	methods: {
		getIconColor(index, slidingPagesSlug) {
			return this.panel === index
				? this.colorsObject[slidingPagesSlug]
				: "white";
		},
		scrollToPanel(i) {
			setTimeout(
				function() {
					this.$vuetify.goTo("#project-" + this._uid + "-" + i, { offset: 70 });
				}.bind(this),
				300
			);
		},
	},
};
</script>

<style lang="scss" scoped>
#app p.description {
	margin-bottom: 1rem;
}
.subtitle {
	font-weight: normal;
	margin-bottom: $spacing-0;
}

.button {
	font-size: $font-size-12;
	margin: $spacing-0 0;
	border-radius: 90px;
	border-top-left-radius: 0;
	padding: 0 $spacing-1;
	margin-bottom: 8px;
}

h3 {
	font-size: $font-size-55;
}

.background {
	padding: $spacing-1;
}

.projects {
	background: #e1e9fc;
	padding: $spacing-0;
	margin: $spacing-1 0;
	position: relative;
}

.project {
	background: transparent !important;
	box-shadow: none !important;
	border: none !important;
	font-weight: bold;
	padding-left: 0 !important;
}

.content {
	padding: $spacing-1;
}

.project-header {
	text-transform: uppercase !important;
	display: inline-flex;
	color: #fff;
}
.project-header .name {
	margin-right: 10px;
	min-width: auto;
	display: inline-block;
}
.project .content {
	font-weight: normal;
}
.project .content a {
	margin-bottom: 1rem;
	display: inline-block;
}
.separator {
	height: 2px;
	flex: 100 !important;
	display: inline-block;
}

.v-item--active {
	background-color: #fff !important;
	box-shadow: 17px 17px 35px -5px rgba(0, 0, 0, 0.2) !important;
	border-radius: 15px !important;
}

.projects-childs {
	background: $light-green;
}

.projects-childs .v-item--active .project-header .name {
	color: $green;
}

.projects-childs .v-item--active .project-header .separator {
	background: $green;
}

.separator {
	background: #fff;
}

.projects-family {
	background: $light-orange;
}

.projects-family .v-item--active .project-header .name {
	color: $orange;
}

.projects-family .v-item--active .project-header .separator {
	background: $orange;
}

.projects-adults {
	background: $light-red;
}

.projects-adults .v-item--active .project-header .name {
	color: $red;
}

.projects-adults .v-item--active .project-header .separator {
	background: $red;
}

.projects-schools .v-item--active .project-header .name {
	color: $purple;
}
.projects-schools .v-item--active .project-header .separator {
	background: $purple;
}
@media only screen and (max-width: 768px) {
	.row {
		display: inline-block;
		width: 100%;
	}
	h3 {
		margin-bottom: $spacing-0;
	}
	.col {
		margin: 0;
		width: 100%;
		max-width: 100%;
	}
	.content {
		padding: $spacing-0;
	}
	.project {
		margin-left: 0;
	}

	.background {
		padding: $spacing-1 0;
	}
}
@media only screen and (max-width: 550px) {
	.projects {
		padding: 1rem;
	}
}
</style>
