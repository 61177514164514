<template>
	<div class="lists" v-html="this.block.saveContent" />
</template>

<style lang="scss">
.v-application .lists ol,
.v-application .lists ul {
	padding-left: 0;
	margin-top: $spacing-0;
}
.lists li {
	border-top: 1px solid #000 !important;
	padding: 1rem 0;
	list-style: none;
}
</style>

<script>
export default {
	name: "core_image",
	props: ["block"],
};
</script>
