import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		announcementSlugs: ["novita", "novità"],
		articleSlugs: ["articoli", "articolo"],
		colorsObject: {
			all: "#497dd6",
			adults: "#eb6a6a",
			schools: "#974fe0",
			childs: "#36bf88",
			family: "#fdad47",
		},
		columnWidth: 40,
		currentRoute: null,
		dotsNumberX: 0,
		dotsNumberY: 0,
		emailForSubscribe: null,
		infoOpeningTime: null,
		infoSubscription: {},
		isAdult: false,
		isSubmitButtonVisible: false,
		municipalities: null,
		pageTitles: null,
		defaultUrlImage: "https://bibliotecaagora.it/wp-content/uploads/2020/10/AGORA20_social.png",
		defaultMetaKeywords: "",
		reportageSlugs: ["reportage", "reportages"],
		reviewSlugs: ["recensioni", "recensione"],
		slidingPagesSlug: null,
		subscriptionDialog: false,
		contactsDialog: false,
		menuMobile: false,
		droppedMenu: false,
		timeoutedMenu: false,
		loadingStatus: false,
		subscriptionFormList: {},
		slugConverter: {
			adulti: "adults",
			"bambini-e-famiglie": "family",
			ragazzi: "childs",
			scuole: "schools",
			tutti: "all",
			adults: "adulti",
			childs: "ragazzi",
			family: "bambini-e-famiglie",
			schools: "scuole",
			all: "tutti",
		},
		validationRules: {
			cap: (value) => {
				const pattern = /^\d{5}$/;
				return pattern.test(value) || "CAP non valido.";
			},
			required: (value) => !!value || "Campo obbligatorio.",
			email: (value) => {
				const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				return pattern.test(value) || "E-mail non valida.";
			},
		},
	},
	mutations: {
		updateProperty(state, { property, value }) {
			state[property] = value;
		},
	},
	actions: {
		updateProperty(context, { property, value }) {
			context.commit("updateProperty", { property, value });
		},
	},
});
