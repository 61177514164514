import { mapState, mapActions } from "vuex";
import MainColumn from "@/components/ui/MainColumn";

export const utilities = {
	components: {
		MainColumn,
	},
	computed: {
		...mapState({
			articleSlugs: "articleSlugs",
			announcementSlugs: "announcementSlugs",
			colorsObject: "colorsObject",
			columnWidth: "columnWidth",
			currentRoute: "currentRoute",
			dotsNumberX: "dotsNumberX",
			dotsNumberY: "dotsNumberY",
			emailForSubscribe: "emailForSubscribe",
			infoOpeningTime: "infoOpeningTime",
			infoSubscription: "infoSubscription",
			isAdult: "isAdult",
			isSubmitButtonVisible: "isSubmitButtonVisible",
			municipalities: "municipalities",
			pageTitles: "pageTitles",
			reviewSlugs: "reviewSlugs",
			reportageSlugs: "reportageSlugs",
			slidingPagesSlug: "slidingPagesSlug",
			subscriptionDialog: "subscriptionDialog",
			contactsDialog: "contactsDialog",
			subscriptionFormList: "subscriptionFormList",
			validationRules: "validationRules",
			windowWidth: "windowWidth",
			windowHeight: "windowHeight",
			menuMobile: "menuMobile",
			droppedMenu: "droppedMenu",
			timeoutedMenu: "timeoutedMenu",
			loadingStatus: "loadingStatus",
			defaultUrlImage: "defaultUrlImage",
			defaultMetaKeywords: "defaultMetaKeywords",
			slugConverter: "slugConverter",
		}),
		mainColumnWidth() {
			return Math.min(25, this.dotsNumberX);
		},
	},
	methods: {
		...mapActions(["updateProperty"]),
		changeUrlWithoutNavigation(params) {
			let currentRoute = this.$route.path;
			let splittedCurrentRoute = currentRoute.split("/");
			if (splittedCurrentRoute.length === 3) {
				let currentTarget = splittedCurrentRoute[2];
				currentRoute = currentRoute.replace(currentTarget, encodeURIComponent(params));
			} else {
				currentRoute = currentRoute + "/" + encodeURIComponent(params);
			}
			history.pushState({}, null, currentRoute);
		},
		calculateWidthColumn(width) {
			return this.columnWidth * width + "px";
		},
		reduceColumns(columns) {
			let mainColumnInDots = parseInt(this.calculateWidthColumn(this.mainColumnWidth)) / this.columnWidth;
			let maxWidthElement = 4;
			if (columns - 0 + maxWidthElement > mainColumnInDots) {
				columns = this.reduceColumns(columns - 1);
			}
			return columns;
		},
		calculateSpace(columns) {
			columns = this.reduceColumns(columns);
			return this.columnWidth * columns + "px";
		},
		get404() {
			this.$router.push({ name: "404" });
		},
		getTextWidth(size, text) {
			let span = document.createElement("span");
			document.body.appendChild(span);

			span.style.font = "Poppins";
			span.style.fontSize = size;
			span.style.height = "auto";
			span.style.width = "auto";
			span.style.position = "absolute";
			span.style.whiteSpace = "no-wrap";
			span.innerHTML = text;

			let width = Math.ceil(span.clientWidth);
			document.body.removeChild(span);
			return width + 10;
		},
		handleSwiperSlideClick(index) {
			this.onNewsClick(this.slides[index].mainCategory, this.slides[index].uri);
		},
		limitNumberWithinRange(num, min, max) {
			return Math.min(Math.max(num, min), max);
		},
		onCategoryClick(category) {
			this.$router.push({
				name: "SlidingPages",
				params: {
					pageSlug: category,
				},
			});
		},
		onArchivesClick(category, target) {
			let params = {};
			if (category) {
				params["category"] = category;
			}
			if (target) {
				params["target"] = target;
			}
			this.$router.push({
				name: "Archives",
				params: params,
			});
		},
		onNewsClick(category, slug) {
			let split = slug.split("/");
			let realSlug = split[split.length - 2];

			this.$router.push({
				name: "News",
				params: {
					category: category.slug,
					postSlug: realSlug,
				},
			});
		},
		loadDone() {
			setTimeout(
				function() {
					this.updateProperty({
						property: "loadingStatus",
						value: false,
					});
				}.bind(this),
				750
			);
		},
		loadStart() {
			this.updateProperty({
				property: "loadingStatus",
				value: true,
			});
		},

		openLinkInNewTab(link) {
			window.open(link);
		},
		parseAttribute(query) {
			return JSON.parse(decodeURIComponent(query));
		},
		randomIntFromInterval(min, max) {
			// min and max included
			return Math.floor(Math.random() * (max - min + 1) + min);
		},
		stripHTML(string) {
			return string.replace(/<[^>]*>?/gm, "");
		},
		setYoastSeo(seo) {
			document.title = seo.title;
		},
	},
	filters: {
		capitalize: function(value) {
			if (!value) return "";
			value = value.toString();
			return value.charAt(0).toUpperCase() + value.slice(1);
		},
	},
};
