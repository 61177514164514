<template>
	<div class="article-card">
		<div
			v-if="mainCategory.slug === 'recensioni'"
			:class="{
				'bottom-align': bottomAlign,
				'review-card': reviewCard,
				review: true,
				header: true
			}"
		>
			<v-slide-y-reverse-transition>
				<img
					:alt="'Immagine ' + target.name"
					v-show="show"
					class="image"
					height="auto"
					:src="image"
					@load="show = true"
				/>
			</v-slide-y-reverse-transition>
			<v-fade-transition>
				<CategoryShape
					v-if="subCategory"
					v-show="show"
					:whiteText="whiteText"
					:category="subCategory.slug"
					:target="target.slug"
				/>
			</v-fade-transition>
			<div v-show="!show" class="placeholder" />
		</div>

		<div
			:class="{
				'bottom-align': bottomAlign,
				header: true,
				notReview: true
			}"
			v-else
		>
			<ImageShape
				:category="mainCategory.slug"
				:srcImage="image"
				:imageSizes="imageSizes"
				class="image notReview"
			/>
		</div>

		<div v-if="isSubheaderVisible">
			<span
				>{{ mainCategory.name
				}}{{ subCategory ? " " + subCategory.name : "" }}</span
			><span class="separator">&bull;</span>

			<span
				:class="whiteText ? 'text--white' : 'target-' + target.slug"
				>{{ target.name }}</span
			>
			<h3 class="font-size-25">{{ title }}</h3>
			<h4 v-if="subtitle" class="subtitle">{{ subtitle }}</h4>
		</div>
	</div>
</template>
<script>
import CategoryShape from "@/components/ui/CategoryShape.vue";
import ImageShape from "@/components/ui/ImageShape.vue";

export default {
	props: [
		"reviewCard",
		"bottomAlign",
		"image",
		"isSubheaderVisible",
		"mainCategory",
		"subCategory",
		"subtitle",
		"target",
		"title",
		"url",
		"whiteText",
		"imageSizes"
	],
	components: {
		CategoryShape,
		ImageShape
	},
	data() {
		return {
			show: false
		};
	}
};
</script>
<style lang="scss" scoped>
.article-card {
	position: relative;
	cursor: pointer;
}
.target-childs {
	color: $green;
}

.target-all {
	color: $blue;
}

.target-adults {
	color: $red;
}

.target-schools {
	color: $purple;
}

.target-family {
	color: $orange;
}

.bottom-align .image {
	bottom: 0;
	position: absolute;
	max-height: 280px;
	z-index: 2;
	max-width: 200px;
}

.review-card {
	height: auto;
	position: relative;
}
.review {
	left: 0;
}

.image.notReview {
	max-width: 280px;
}
.notReview {
	left: 0;
	right: 0;
}

.header {
	height: 280px;
	position: relative;
}
.placeholder {
	height: 280px;
}

.separator {
	padding: 0 4px;
}

span {
	display: inline-block;
	text-transform: uppercase;
	font-size: $font-size-14;
	padding: 0.8rem 0;
}

h3 {
	margin-top: -5px;
}

@media only screen and (max-width: 1024px) {
	.header {
		height: 300px;
	}
	.image {
		/* max-height: 300px; */
	}
}
@media only screen and (max-width: 768px) {
	.review.header {
		max-height: 280px;
	}
	.notReview {
		height: calc(50vw - 7rem);
		max-height: auto;
	}
	#app .header .image.notReview {
		width: calc(50vw - 7rem);
		max-height: 9000px;
		max-width: 9000px;
		left: 50%;
		transform: translateX(-50%);
	}

	.negative-offset {
		margin-left: calc(-40%);
		margin-right: 0;
	}
}

@media only screen and (max-width: 550px) {
	.review.header {
		max-height: 280px;
		min-height: 280px;
	}
	.notReview,
	.header {
		height: calc(95vw - 6rem);
	}
	#app .header .image.notReview {
		width: calc(95vw - 6rem);
		max-height: 9000px;
		max-width: 9000px;
	}
}
</style>
