<template>
	<MainColumn class="content">
		<div class="rules">
			<v-row>
				<v-col class="responsive" cols="4">
					<h3 class="font-size-35">{{ block.attributes.title }}</h3>
				</v-col>
				<v-col offset="1" cols="7">
					<p class="description" v-html="block.attributes.content"></p>

					<v-expansion-panels flat>
						<v-expansion-panel
							@click="scrollToPanel(i)"
							flat
							class="rule"
							v-for="(rule, i) in parseAttribute(block.attributes.rules)"
							:key="i"
							:id="'rule-' + _uid + '-' + i"
						>
							<v-expansion-panel-header flat class="rule-header">
								<div class="name">{{ rule.name }}</div>
								<div class="separator"></div>
								<template v-slot:actions>
									<v-icon :color="colorsObject['all']">
										mdi-plus
									</v-icon>
								</template>
							</v-expansion-panel-header>
							<v-expansion-panel-content class="rule-content">
								<div v-html="rule.content_rule" class="font-weight-regular" />
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-col>
			</v-row>
		</div>
	</MainColumn>
</template>

<script>
import MainColumn from "@/components/ui/MainColumn";

export default {
	props: ["block", "blocks", "parentBlock"],
	components: { MainColumn },
	data() {
		return {
			info: "",
		};
	},
	mounted() {},
	methods: {
		scrollToPanel(i) {
			setTimeout(
				function() {
					this.$vuetify.goTo("#rule-" + this._uid + "-" + i, { offset: 70 });
				}.bind(this),
				300
			);
		},
	},
};
</script>

<style lang="scss" scoped>
#app p.description {
	margin-bottom: 1rem;
}
.rules {
	margin: $spacing-1 0;
	background: #e1e9fc;
	padding: $spacing-1;
}

.rule {
	background: transparent !important;
	box-shadow: none !important;
	border: none !important;
	color: #497dd6;
	font-weight: bold;
	padding-left: 0 !important;
	margin-left: -46px;
}

.rule-header {
	color: #497dd6;
	text-transform: uppercase !important;
	display: inline-flex;
}
.rule-header .name {
	margin-right: 10px;
	min-width: auto;
	display: inline-block;
}
.rule .content {
	font-weight: normal;
}
.rule .content a {
	margin-bottom: 1rem;
	display: inline-block;
}
.separator {
	height: 2px;
	flex: 100 !important;
	display: inline-block;
	background: #497dd6;
}

.v-item--active {
	background-color: #fff !important;
	box-shadow: 17px 17px 35px -5px rgba(0, 0, 0, 0.2) !important;
	border-radius: 15px !important;
}

@media only screen and (max-width: 768px) {
	.row {
		display: inline-block;
		width: 100%;
	}
	h3 {
		margin-bottom: $spacing-0;
	}
	.col {
		margin: 0;
		width: 100%;
		max-width: 100%;
	}
	.rules {
		padding: $spacing-0;
	}
	.rule {
		margin-left: 0;
	}
}
</style>
