<template>
	<div
		:id="block ? block.attributes.section_name : null"
		ref="wrapper"
		:class="{
			['panel background-' +
			JSON.parse(block.attributes.background).slug]: true,
			['color-' + JSON.parse(block.attributes.color).slug]: true,
			['template-' + block.attributes.template]: true,
		}"
	>
		<MainColumn>
			<RenderBlocks :blocks="block.innerBlocks" :parentBlock="block" />
		</MainColumn>
	</div>
</template>

<script>
export default {
	name: "qzrstudio_panel",
	props: ["block", "parentBlock", "offset", "padding"],
	components: {
		RenderBlocks: () => import("@/components/blocks/RenderBlocks.vue"),
	},
	data() {
		return {
			width: null,
			height: null,
			center: null,
		};
	},
};
</script>

<style lang="scss" scoped>
.panel {
	background: transparent;
}

.panel {
	padding: $spacing-1 0;
}
</style>
