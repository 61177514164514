<template>
	<div>
		<div class="map" ref="googleMap"></div>
		<template v-if="Boolean(this.google) && Boolean(this.map)">
			<slot :google="google" :map="map" />
		</template>
	</div>
</template>

<style lang="scss" scoped>
.map {
	height: 536px;
}
</style>

<script>
import GoogleMapsApiLoader from "google-maps-api-loader";

export default {
	name: "GoogleMapLoader",
	props: ["markers", "position"],
	data() {
		return {
			apiKey: "AIzaSyAP7K3KFeEEvNA8owcRzs5vYv4BVDjpVgE",
			google: null,
			items: null,
			map: null,
			mapConfig: {
				zoom: 6,
				styles: [
					{
						elementType: "geometry",
						stylers: [
							{
								color: "#f5f5f5",
							},
						],
					},
					{
						elementType: "labels.icon",
						stylers: [
							{
								visibility: "off",
							},
						],
					},
					{
						elementType: "labels.text.fill",
						stylers: [
							{
								color: "#616161",
							},
						],
					},
					{
						elementType: "labels.text.stroke",
						stylers: [
							{
								color: "#f5f5f5",
							},
						],
					},
					{
						featureType: "administrative.land_parcel",
						elementType: "labels.text.fill",
						stylers: [
							{
								color: "#bdbdbd",
							},
						],
					},
					{
						featureType: "poi",
						elementType: "geometry",
						stylers: [
							{
								color: "#eeeeee",
							},
						],
					},
					{
						featureType: "poi",
						elementType: "labels.text.fill",
						stylers: [
							{
								color: "#757575",
							},
						],
					},
					{
						featureType: "poi.park",
						elementType: "geometry",
						stylers: [
							{
								color: "#e5e5e5",
							},
						],
					},
					{
						featureType: "poi.park",
						elementType: "labels.text.fill",
						stylers: [
							{
								color: "#9e9e9e",
							},
						],
					},
					{
						featureType: "road",
						elementType: "geometry",
						stylers: [
							{
								color: "#ffffff",
							},
						],
					},
					{
						featureType: "road.arterial",
						elementType: "labels.text.fill",
						stylers: [
							{
								color: "#757575",
							},
						],
					},
					{
						featureType: "road.highway",
						elementType: "geometry",
						stylers: [
							{
								color: "#dadada",
							},
						],
					},
					{
						featureType: "road.highway",
						elementType: "labels.text.fill",
						stylers: [
							{
								color: "#616161",
							},
						],
					},
					{
						featureType: "road.local",
						elementType: "labels.text.fill",
						stylers: [
							{
								color: "#9e9e9e",
							},
						],
					},
					{
						featureType: "transit.line",
						elementType: "geometry",
						stylers: [
							{
								color: "#e5e5e5",
							},
						],
					},
					{
						featureType: "transit.station",
						elementType: "geometry",
						stylers: [
							{
								color: "#eeeeee",
							},
						],
					},
					{
						featureType: "water",
						elementType: "geometry",
						stylers: [
							{
								color: "#c9c9c9",
							},
						],
					},
					{
						featureType: "water",
						elementType: "labels.text.fill",
						stylers: [
							{
								color: "#9e9e9e",
							},
						],
					},
				],
			},
			selectedMunicipality: null,
			search: null,
		};
	},
	async mounted() {
		this.mapConfig.center = this.position;
		const googleMapApi = await GoogleMapsApiLoader({
			libraries: ["places"],
			apiKey: this.apiKey,
		});
		this.google = googleMapApi;
		this.initializeMap();
	},

	methods: {
		initializeMap() {
			const mapContainer = this.$refs.googleMap;
			this.map = new this.google.maps.Map(mapContainer, this.mapConfig);

			if (this.markers.length > 1) {
				let bounds = new this.google.maps.LatLngBounds();
				this.markers.map((marker) => {
					bounds.extend(marker.position);
				});
				this.map.fitBounds(bounds);
			} else {
				this.map.setZoom(16);
				this.map.setCenter(this.markers[0].position);
			}
		},
	},
};
</script>
<style scoped>
.search-google-maps {
	width: 40%;
}
</style>
