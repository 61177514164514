var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.pageTitles)?_c('header',{class:{
		[_vm.currentRoute && _vm.currentRoute.name
			? 'menu-' + _vm.currentRoute.name.toLowerCase()
			: '']: _vm.currentRoute,
		'menu-wrap': true,
		dropped: _vm.droppedMenu || _vm.contactsDialog || _vm.subscriptionDialog,
		open: _vm.menuMobile,
		closed: !_vm.menuMobile,
		timeoutedMenu: _vm.timeoutedMenu && !_vm.contactsDialog && !_vm.subscriptionDialog,
		slidingMenu: this.visibleItems.length > 0,
	}},[_c('v-row',{staticClass:"top-bar"},[_c('MainColumn',[_c('div',{staticClass:"logo-wrapper",attrs:{"align-self":"center"}},[_c('div',{staticClass:"logo d-inline-block",on:{"click":_vm.onLogoClick}},[_vm._v(" "+_vm._s(_vm.logoTitle)+" ")]),_c('div',{class:'d-inline-block selector color-' + _vm.selectedSlug},[_c('div',{on:{"click":_vm.onButtonClick}},[_c('strong',[_vm._v(_vm._s(_vm.selectedCategory))]),_c('hr',{staticClass:"solid mx-2 d-inline-block"}),_c('v-icon',{class:{
								'icon-active': this.visibleItems.length > 0,
								icon: true,
							},attrs:{"color":_vm.droppedMenu ||
								_vm.menuMobile ||
								this.visibleItems.length > 0 ||
								_vm.$vuetify.breakpoint.width > 1024
									? 'black'
									: 'white'}},[_vm._v("mdi-plus")])],1)])]),_c('div',{class:_vm.menuMobile ? 'menus open' : 'menus closed'},[_c('div',{staticClass:"menu mobile-only",attrs:{"text":""},on:{"click":_vm.onLogoClick}},[_vm._v(" Home ")]),_vm._l((_vm.pageTitles),function(page){return _c('div',{key:page.path,class:{
						menu: true,
						button: page.path === '/preiscrizione' && !_vm.menuMobile,
						'pre-iscriviti': page.path === '/preiscrizione',
						selected: _vm.currentRoute && _vm.currentRoute.path === page.path,
					},attrs:{"text":""},on:{"click":function($event){return _vm.onPageClick(page.path)}}},[_vm._v(" "+_vm._s(page.label)+" ")])}),_c('Modal',{attrs:{"slug":"contatti","fromHeader":_vm.fromHeader}}),_c('Subscription',{attrs:{"fromHeader":_vm.fromHeader}})],2),_c('div',{class:{
					'bar-icon-wrapper': true,
					'medium-screen': true,
					right: true,
					active: _vm.menuMobile,
				},attrs:{"id":"menu-btn"},on:{"click":function($event){_vm.updateProperty({
						property: 'menuMobile',
						value: !_vm.menuMobile,
					});
					_vm.closeMenu();}}},[_c('div',{staticClass:"bar-icon"}),_c('div',{staticClass:"bar-icon"}),_c('div',{staticClass:"bar-icon"})])])],1),_c('div',{staticClass:"targets"},_vm._l((_vm.visibleItems),function(item,i){return _c('v-row',{key:i,class:{
				['background-' + item.target]: true,
				menuItem: true,
			},style:({ 'z-index': _vm.items.length + 1 - i })},[_c('v-slide-y-transition',{attrs:{"hide-on-leave":""}},[_c('v-col',[_c('div',{staticClass:"font-size-40 font-weight-bold target",on:{"click":function($event){return _vm.onTargetClick(item.target, item.slug)}}},[_c('MainColumn',[_vm._v(_vm._s(item.text))])],1)])],1)],1)}),1),_c('v-overlay',{staticClass:"fastTransition",attrs:{"opacity":"0.9","z-index":"1","value":_vm.showOverlay},nativeOn:{"click":function($event){return _vm.closeMenu()}}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }