<template>
	<div class="slider">
		<Background
			class="elements-layout"
			:target="activeSlide"
			color="rgba(255,255,255,0.3)"
		>
			<div v-if="slides[currentIndex]">
				<PatternShapes
					ref="patternShapes"
					:backgroundTarget="slides[currentIndex].target"
				/>
				<MainColumn class="container">
					<MovingLogo
						:logoPositions="logoPositions"
						:offset="0"
						:target="slides[currentIndex].slug"
						:width="mainColumnWidth"
						color="white"
					/>
					<div
						@click="scroll"
						:class="'button-slide background-' + activeSlide"
					>
						<div class="scroll-text">
							<v-icon class="icon">mdi-chevron-down</v-icon>
							Scorri
						</div>
					</div>
					<PaginationSlideshow
						class="pagination"
						:currentIndex="currentIndex + 1"
						:numberOfPosts="slides.length"
						:target="activeSlide"
						@onPaginationClick="onPaginationClick"
					></PaginationSlideshow>
				</MainColumn>
			</div>
		</Background>

		<swiper
			class="swipe"
			ref="swiper"
			:options="swiperOption"
			@slideChange="onSlideChange"
		>
			<swiper-slide
				v-for="(slide, i) in slides"
				:key="i"
				class="slide"
				id="element-near-top"
			>
				<MainColumn class="container">
					<div class="content">
						<div class="col-image">
							<div v-if="slide.image" :class="'image-wrapper'">
								<template transition="fade-transition">
									<ImageShape
										type="home"
										category="reportages"
										:blend="true"
										:srcImage="slide.image.url"
									/>
								</template>
							</div>
						</div>
						<div class="col-text">
							<div class="text-wrapper">
								<h4 class="target">
									{{
										slide.target == "childs"
											? "Ragazzi"
											: slide.target == "adults"
											? "Giovani e adulti"
											: slide.target == "family"
											? "Bambini e famiglie"
											: slide.target == "schools"
											? "Scuole"
											: "Per tutti"
									}}
								</h4>
								<h2 class="font-size-50">
									{{ slide.title }}
								</h2>
								<h3 class="font-size-25" v-html="slide.subtitle" />
								<v-btn
									v-if="slide.label"
									depressed
									@click="openLinkInNewTab(slide.url)"
									:class="`infoButton background-${slide.target}`"
									>{{ slide.label }}</v-btn
								>
							</div>
						</div>
					</div>
				</MainColumn>
			</swiper-slide>
		</swiper>
		<MainColumn>
			<v-expand-transition>
				<div
					class="openingtime"
					v-if="infoOpeningTime"
					v-html="infoOpeningTime.time"
					@click="
						updateProperty({
							property: 'contactsDialog',
							value: true,
						})
					"
				></div>
			</v-expand-transition>
		</MainColumn>
	</div>
</template>

<script>
import Background from "@/components/ui/Background.vue";
import MainColumn from "@/components/ui/MainColumn.vue";
import MovingLogo from "@/components/ui/MovingLogo.vue";
import PaginationSlideshow from "@/components/ui/PaginationSlideshow.vue";
import PatternShapes from "@/components/ui/PatternShapes.vue";
import ImageShape from "@/components/ui/ImageShape.vue";

export default {
	props: ["block", "blocks", "parentBlock"],
	components: {
		Background,
		MovingLogo,
		MainColumn,
		PatternShapes,
		PaginationSlideshow,
		ImageShape,
	},
	data() {
		return {
			currentIndex: 0,
			slides: [],
			activeSlide: null,
			logoPositions: [
				{
					left: 0,
					top: 1,
					min: { x: 0, y: 1 },
					max: { x: 5, y: 1 },
				},
				{
					left: 6,
					top: 1,
					min: { x: 6, y: 1 },
					max: { x: 10, y: 1 },
				},
				{
					left: 11,
					top: 1,
					min: { x: 11, y: 1 },
					max: { x: 15, y: 1 },
				},
				{
					left: 16,
					top: 1,
					min: { x: 16, y: 1 },
					max: { x: 20, y: 1 },
				},
				{
					left: 21,
					top: 1,
					min: { x: 21, y: 1 },
					max: { x: 25, y: 1 },
				},
			],
			swiperOption: {
				slidesPerView: 1,
				spaceBetween: 150,
				centeredSlides: false,
				activeColor: null,
				autoplay: {
					delay: 5000,
				},
			},
		};
	},
	computed: {
		swiper() {
			return this.$refs.swiper.$swiper;
		},
	},
	async mounted() {
		this.slides = this.parseAttribute(this.block.attributes.slides);
		this.activeSlide = this.slides[0].target;
		if (!this.infoOpeningTime) {
			let response = await this.$api.get("/wp-json/qzrstudio/v1/openingtime");
			setTimeout(() => {
				this.updateProperty({
					property: "infoOpeningTime",
					value: response.data,
				});
			}, 1500);
		}
	},

	methods: {
		getType(slide) {
			let checkAllType = false;
			slide.qzrTargets.edges.forEach((element) => {
				if (element.node.slug == "all") checkAllType = true;
				else checkAllType = false;
			});
			return checkAllType ? "all" : slide.qzrTargets.edges[0].node.slug;
		},
		onSlideChange() {
			this.currentIndex = this.swiper.activeIndex;
			this.activeSlide = this.slides[this.swiper.activeIndex].target;
			this.$refs.patternShapes.changePattern();
		},
		onPaginationClick(index) {
			this.swiper.slideTo(index);
		},
		scroll() {
			let offset = 100;
			this.$vuetify.goTo(window.innerHeight - offset);
		},
	},
};
</script>

<style lang="scss" scoped>
/* Container */
.swiper-container {
	position: relative;
	height: calc(100vh - 138px);
}
.slider,
.slide,
.container {
	position: relative;
	height: calc(100vh - 60px);
}

.elements-layout {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 0;
}

/* Main Elements */
.content {
	position: absolute;
	top: 50%;
	width: 90%;
	transform: translateY(-50%);
}
.col-image,
.col-text {
	width: calc(50% - 25px);
	float: left;
}

.col-text {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: 0;
	float: right;
}

/* Texts */
#app h2 {
	margin-bottom: 1rem;
	color: #fff;
}
#app h3 {
	color: #fff;
	font-weight: normal;
}

.target {
	font-size: $font-size-14;
	text-transform: uppercase;
	margin-bottom: 1rem;
	color: #fff;
}

/* Button Slide */
.button-slide {
	position: absolute;
	border-radius: 50%;
	cursor: pointer;
	display: inline-block;
	height: 75px;
	right: 0;
	bottom: 0;
	transform: rotate(-90deg);
	transition: 0.3s all;
	z-index: 1000;
	width: 75px;
}
.scroll-text {
	height: 75px;
	background: transparent;
	line-height: 75px;
	text-align: center;
	font-size: $font-size-12;
	text-transform: uppercase;
}

.icon {
	color: #fff;
	animation: float 1.5s ease-in-out infinite;
	font-size: 20px;
	top: -1px;
}

/* Assets */

.infoButton {
	border: 2px solid #fff;
	margin-top: $spacing-0;
	border-radius: 50px 0 50px 50px;
	font-size: $font-size-12;
}
.image-wrapper {
	max-width: 40vh;
	max-height: 40vh;
	margin: auto;
	border-radius: 100%;
}

@keyframes float {
	0% {
		transform: translateX(-5px) rotate(90deg);
	}
	50% {
		transform: translateX(4px) rotate(90deg);
	}
	100% {
		transform: translateX(-5px) rotate(90deg);
	}
}

/* Pagination */
.pagination {
	position: absolute;
	bottom: 36px;
	z-index: 999;
}

/* Opening Time */
.openingtime {
	font-size: $font-size-14;
	border-radius: 25px;
	border-bottom-left-radius: 0;
	padding: 1rem $spacing-0;
	bottom: -52px;
	/* 	transform: translateY(-50%);
 */
	background: #fff;
	z-index: 4;
	cursor: pointer;
	position: absolute;
}
.openingtime span {
	float: left;
	margin-right: 6px;
	font-weight: bold !important;
}

.openingtime p {
	margin-bottom: 0;
}

.openingtime a {
	color: #000;
}
@media only screen and (max-width: 1024px) {
	.pagination {
		display: none;
	}
}
@media only screen and (max-width: 768px) {
	.openingtime {
		bottom: 1rem;
	}
	.slider,
	.swiper-container,
	.slide,
	.container {
		height: calc(100vh - 67px);
	}

	#app h3,
	#app h2 {
		font-size: $font-size-35;
		text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.4);
	}

	.button-slide,
	.pagination {
		display: none;
	}

	.col-image,
	.col-text {
		width: 100%;
	}

	.col-image {
		text-align: center;
		padding-top: 20%;
	}

	.image-wrapper {
		border: 1px solid transparent;
		display: inline-block;
		width: 100%;
		max-width: 40vh;
		max-height: 40vh;
	}

	.image-wrapper img {
		max-width: 40vh;
		max-height: 40vh;
	}

	.content {
		margin: auto;
		height: calc(100vh - 134px);
		position: relative;
		top: auto;
		transform: none;
	}
	.col-text {
		position: absolute;
		bottom: 25px;
		left: auto;
		top: auto;
		transform: none;
	}
}

@media only screen and (max-width: 320px) {
	#app h3,
	#app h2 {
		font-size: $font-size-20;
	}

	#app h3 {
		font-size: $font-size-16 !important;
	}

	#app .image-wrapper {
		max-height: 210px;
		max-width: 210px;
	}
}

/*




@media only screen and (max-width: 1024px) {
	.image-wrapper,
	.text-wrapper,
	img {
		position: relative;
		transform: none;
		left: auto;
		top: auto;
	}

	.wrapper {
		/* padding-top: 5vh;
		top: 32px;
		position: relative;
	}



	.col-image {
		text-align: center;
		top: 50%;
		transform: translateY(-50%);
	}

	.col-text {
		bottom: 5vh;
		left: 0;
		right: 0;
	}

	.button-slide {
		width: 70px;
		height: 70px;
	}

	.button-slide .scroll-text {
		line-height: 70px;
		height: 70px;
		font-size: 10px;
	}
	.text-wrapper {
		max-width: 400px;
		margin: auto;
	}
}

@media only screen and (max-width: 768px) {
	.pagination {
		display: none;
	}

	#app h3,
	#app h2 {
		font-size: $font-size-35;
	}
}
@media only screen and (max-width: 550px) {
	.button-slide {
		display: none;
	}

	.image-wrapper {
		max-width: 280px;
		max-height: 280px;
	}

	#app h3,
	#app h2 {
		font-size: $font-size-35;
	}
}

.lazyblock_slideshow_fullpage {
	max-width: 100vw;
}

@media only screen and (max-width: 320px) {
	#app h3,
	#app h2 {
		font-size: $font-size-20;
	}

	#app h3 {
		font-size: $font-size-16 !important;
	}

	#app .image-wrapper {
		max-height: 210px;
		max-width: 210px;
	}
}
*/
</style>
