<template>
	<header
		v-if="pageTitles"
		:class="{
			[currentRoute && currentRoute.name
				? 'menu-' + currentRoute.name.toLowerCase()
				: '']: currentRoute,
			'menu-wrap': true,
			dropped: droppedMenu || contactsDialog || subscriptionDialog,
			open: menuMobile,
			closed: !menuMobile,
			timeoutedMenu: timeoutedMenu && !contactsDialog && !subscriptionDialog,
			slidingMenu: this.visibleItems.length > 0,
		}"
	>
		<v-row class="top-bar">
			<MainColumn>
				<div class="logo-wrapper" align-self="center">
					<div class="logo d-inline-block" @click="onLogoClick">
						{{ logoTitle }}
					</div>
					<div :class="'d-inline-block selector color-' + selectedSlug">
						<div @click="onButtonClick">
							<strong>{{ selectedCategory }}</strong>
							<hr class="solid mx-2 d-inline-block" />
							<v-icon
								:color="
									droppedMenu ||
									menuMobile ||
									this.visibleItems.length > 0 ||
									$vuetify.breakpoint.width > 1024
										? 'black'
										: 'white'
								"
								:class="{
									'icon-active': this.visibleItems.length > 0,
									icon: true,
								}"
								>mdi-plus</v-icon
							>
						</div>
					</div>
				</div>
				<div :class="menuMobile ? 'menus open' : 'menus closed'">
					<div class="menu mobile-only" text @click="onLogoClick">
						Home
					</div>
					<div
						v-for="page in pageTitles"
						text
						:class="{
							menu: true,
							button: page.path === '/preiscrizione' && !menuMobile,
							'pre-iscriviti': page.path === '/preiscrizione',
							selected: currentRoute && currentRoute.path === page.path,
						}"
						:key="page.path"
						@click="onPageClick(page.path)"
					>
						{{ page.label }}
					</div>
					<Modal slug="contatti" :fromHeader="fromHeader" />
					<Subscription :fromHeader="fromHeader" />
				</div>

				<div
					id="menu-btn"
					:class="{
						'bar-icon-wrapper': true,
						'medium-screen': true,
						right: true,
						active: menuMobile,
					}"
					@click="
						updateProperty({
							property: 'menuMobile',
							value: !menuMobile,
						});
						closeMenu();
					"
				>
					<div class="bar-icon"></div>
					<div class="bar-icon"></div>
					<div class="bar-icon"></div>
				</div>
			</MainColumn>
		</v-row>

		<div class="targets">
			<v-row
				v-for="(item, i) in visibleItems"
				:key="i"
				:class="{
					['background-' + item.target]: true,
					menuItem: true,
				}"
				:style="{ 'z-index': items.length + 1 - i }"
			>
				<v-slide-y-transition hide-on-leave>
					<v-col>
						<div
							@click="onTargetClick(item.target, item.slug)"
							class="font-size-40 font-weight-bold target"
						>
							<MainColumn>{{ item.text }}</MainColumn>
						</div>
					</v-col>
				</v-slide-y-transition>
			</v-row>
		</div>

		<v-overlay
			class="fastTransition"
			@click.native="closeMenu()"
			opacity="0.9"
			z-index="1"
			:value="showOverlay"
		></v-overlay>
	</header>
</template>

<script>
import { GET_PAGES_TITLE } from "@/queries/Pages.js";
import Modal from "@/components/ui/Modal.vue";
import Subscription from "@/components/ui/Subscription.vue";

export default {
	name: "Header",
	props: ["value", "color"],
	components: {
		Modal,
		Subscription,
	},
	data() {
		return {
			logoTitle: "Biblioteca Civica di Lucca",
			fromHeader: false,
			items: [
				{
					text: "Bambini e famiglie",
					target: "family",
					slug: "bambini-e-famiglie",
				},
				{
					text: "Ragazzi",
					target: "childs",
					slug: "ragazzi",
				},
				{
					text: "Giovani e Adulti",
					target: "adults",
					slug: "adulti",
				},
				{
					text: "Scuole",
					target: "schools",
					slug: "scuole",
				},
				{
					text: "Home",
					target: "home",
					slug: "",
				},
			],
			menus: null,
			selectedSlug: "home",
			selectedCategory: "Per te",
			showOverlay: false,
			visibleItems: [],
		};
	},
	watch: {
		$route(to) {
			this.updateProperty({
				property: "currentRoute",
				value: to,
			});
		},
	},
	async mounted() {
		let logoTitles = ["Biblioteca Civica di Lucca", "AGORÀ"];
		let logoIndex = 0;

		if (window.innerWidth < 1024) {
			setInterval(() => {
				this.logoTitle = logoTitles[logoIndex];
				logoIndex = logoIndex < logoTitles.length - 1 ? logoIndex + 1 : 0;
			}, 3000);
		} else {
			this.logoTitle = logoTitles[0];
		}

		let query = {
			query: GET_PAGES_TITLE(),
		};
		let data = await this.$apollo.query(query);

		let pageTitle = data.data.menu.menuItems.nodes;
		this.updateProperty({
			property: "pageTitles",
			value: pageTitle,
		});
		this.selectedSlug = this.$route.params.slug;
	},
	methods: {
		onPageClick(path) {
			switch (path) {
				case "/contatti":
					this.updateProperty({
						property: "currentRoute",
						value: {
							path: path,
						},
					});
					this.onContactsClick();
					break;

				case "/preiscrizione":
					this.updateProperty({
						property: "currentRoute",
						value: {
							path: path,
						},
					});
					this.onSubscriptionClick();
					break;

				default:
					if (path.includes("http")) {
						window.open(path, "_blank");
					} else {
						this.$router.push({ path: path });
					}
					break;
			}
			this.closeMenu();
			this.updateProperty({
				property: "menuMobile",
				value: false,
			});
		},
		onButtonClick() {
			if (this.visibleItems.length === 0) {
				this.openMenu();
				this.updateProperty({
					property: "menuMobile",
					value: false,
				});
			} else {
				this.closeMenu();
			}
		},
		onLogoClick() {
			this.closeMenu();
			this.updateProperty({
				property: "menuMobile",
				value: false,
			});
			this.$router.push({ name: "Home" });
		},
		onContactsClick() {
			this.updateProperty({
				property: "subscriptionDialog",
				value: false,
			});
			this.updateProperty({
				property: "menuMobile",
				value: false,
			});
			if (this.contactsDialog) {
				this.$router.go(-1);
			} else {
				this.fromHeader = true;
				history.pushState({}, null, "/contatti");
			}
			this.updateProperty({
				property: "contactsDialog",
				value: !this.contactsDialog,
			});
		},
		onSubscriptionClick() {
			this.updateProperty({
				property: "contactsDialog",
				value: false,
			});

			if (this.subscriptionDialog) {
				this.$router.go(-1);
			} else {
				this.fromHeader = true;
				history.pushState({}, null, "/preiscrizione");
			}

			this.updateProperty({
				property: "menuMobile",
				value: false,
			});
			this.updateProperty({
				property: "subscriptionDialog",
				value: !this.subscriptionDialog,
			});
		},
		onTargetClick(target, pageSlug) {
			if (
				!(
					window.location.href.includes(this.slugConverter[target]) ||
					(this.$route.path === "/" && target == "home")
				)
			) {
				if (target !== "home") {
					this.$router.push({
						name: "SlidingPages",
						params: { pageSlug: pageSlug },
					});
				} else {
					this.$router.push({
						name: "Home",
					});
				}
			}
			this.closeMenu();
			this.updateProperty({
				property: "menuMobile",
				value: false,
			});
		},
		openMenu() {
			this.showOverlay = true;
			this.updateProperty({
				property: "droppedMenu",
				value: true,
			});
			for (let i in this.items) {
				let item = this.items[i];
				setTimeout(
					function() {
						this.visibleItems.push(item);
					}.bind(this),
					i * 75
				);
			}
		},
		closeMenu() {
			this.showOverlay = false;
			this.updateProperty({
				property: "droppedMenu",
				value: false,
			});
			for (let i in this.items) {
				setTimeout(
					function() {
						this.visibleItems.pop();
					}.bind(this),
					i * 75
				);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.selector {
	margin-left: 8px;
}
#menu-btn {
	position: absolute;
	top: 3px;
	right: 0;
	z-index: 10;
	cursor: pointer;
	display: none;
}
.bar-icon-wrapper {
	padding: 5px;
}
.bar-icon-wrapper.active {
	z-index: 99;
}

.bar-icon {
	height: 2px;
	width: 30px;
	background: black;
	display: block;
	opacity: 1;
	margin-top: 5px;
	transition: all 0.15s linear;
	transform-origin: 50% 50%;
}

.active .bar-icon {
	width: 25px;
}
.active .bar-icon:nth-child(1) {
	transform: rotate(45deg) translate3d(5px, 5px, 0);
}

.active .bar-icon:nth-child(2) {
	opacity: 0;
}

.active .bar-icon:nth-child(3) {
	transform: rotate(-45deg) translate3d(5px, -5px, 0);
}
.selector:hover {
	cursor: pointer;
}
.targets {
	position: relative;
	z-index: 6;
}
.top-bar {
	position: relative;
	z-index: 1000;
	background: #fff;
	box-shadow: 10px -20px 25px 4px #000;
}
.selector {
	color: #000;
	font-size: $font-size-16;
}
.icon {
	font-size: 24px;
	transition: 0.3s all;
}
.icon-active {
	transform: rotate(135deg);
}
.menus {
	text-align: right;
}
.logo {
	font-size: $font-size-16;
	line-height: $menu-height;
	display: inline-block;
	cursor: pointer;
}

.button {
	border-radius: 50px;
	border-top-right-radius: 0;
	background: #497dd6;
	letter-spacing: normal;
	color: #fff;
	margin-left: 24px;
	font-size: $font-size-12;
}

.menu {
	text-transform: uppercase;
	letter-spacing: unset;
	font-size: $font-size-12;
	display: inline-block;
	cursor: pointer;
	line-height: 40px;
	transition: 0.3s all;
	padding: 0 12px;
	margin: 10px 0;
}

.menuItem {
	box-shadow: 0px 2px 66px -22px #000;
	-webkit-box-shadow: 0px 2px 66px -22px #000;
	-moz-box-shadow: 0px 2px 66px -22px #000;
	-o-box-shadow: 0px 2px 66px -22px #000;
	position: relative;
	margin: 0 auto;
	padding: $spacing-0 0;
}
.menu.selected {
	font-weight: bold;
}
.menu-wrap {
	position: fixed;
	top: 0;
	z-index: 10000;
	width: 100%;
	background: #fff;
	transition: 0.3s all;
}

.right {
	text-align: right;
}
hr.solid {
	background-color: black;
	border: 1px solid #000;
	margin: 0 auto;
	vertical-align: middle;
	width: 60px;
}
.fastTransition {
	top: $menu-height;
	transition: 0.1s cubic-bezier(0.25, 0.8, 0.5, 1), z-index 1ms;
}

.logo-wrapper {
	width: 40%;
	float: left;
}
.target {
	cursor: pointer;
}

.mobile-only {
	display: none;
}

.menu-wrap:before {
	content: "";
	max-height: 0px;
	background: #fff;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transition: 0.3s all;
}

@media only screen and (max-width: 1024px) {
	.timeoutedMenu.dropped.closed:not(.slidingMenu) {
		opacity: 0;
	}
	.slidingMenu .top-bar {
		background: #fff;
	}
	.dropped .top-bar {
		background: #fff;
	}

	.menu {
		line-height: 60px;
		padding: 0;
	}

	.menu-archives,
	.menu-news,
	.menu-defaultpage {
		opacity: 1;
	}
	.selector,
	.logo {
		margin: 0;
		width: 80%;
		line-height: 1.5;
	}
	.menu-wrap .bar-icon {
		background: #fff;
	}

	.menus.open .menu {
		color: #000;
	}

	.menu-wrap.open .bar-icon,
	.menu-wrap.dropped .bar-icon,
	.menu-archives .bar-icon,
	.menu-news .bar-icon,
	.menu-defaultpage .bar-icon {
		background: #000;
	}
	.menu-wrap,
	.selector,
	hr.solid,
	.icon {
		color: #fff;
		border-color: #fff;
	}

	.menu-wrap.dropped,
	.slidingMenu,
	.slidingMenu hr.solid,
	.slidingMenu .selector,
	.menu-wrap.dropped .selector,
	.menu-wrap.dropped hr.solid,
	.menu-wrap.dropped hr.solid,
	.menu-wrap.dropped .icon,
	.menu-wrap.open hr.solid,
	.menu-wrap.open .selector,
	.menu-wrap.open .icon,
	.menu-defaultpage .selector,
	.menu-defaultpage .icon,
	.menu-news .selector,
	.menu-news .icon,
	.menu-archives .selector,
	.menu-archives .icon,
	.menu-defaultpage hr.solid,
	.menu-defaultpage .icon,
	.menu-news hr.solid,
	.menu-news .icon,
	.menu-archives hr.solid,
	.menu-archives .icon,
	.menu-wrap.open .icon,
	.menu-wrap.open,
	.menu-archives,
	.menu-news,
	.menu-defaultpage {
		color: #000 !important;
		border-color: #000;
	}

	.slidingMenu #menu-btn .bar-icon {
		background: #000;
	}

	.menu-wrap.dropped:before,
	.menu-archives:before,
	.menu-news:before,
	.menu-defaultpage:before {
		color: #000;
		max-height: 100px;
		box-shadow: 3px -16px 26px 1px #000;
	}
	.top-bar {
		height: $menu-height;
		background: transparent;
		box-shadow: none;
	}

	.logo-wrapper {
		width: 80%;
		float: left;
	}
	.mobile-only {
		display: block;
	}
	.button.pre-iscriviti {
		display: none;
	}
	.menus {
		padding-top: $menu-height;
		background: #fff;
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		transition: 0.3s all;
		height: 0;
		overflow: hidden;
		z-index: -1;
	}
	.menus.closed {
		background: transparent;
	}
	.menu-wrap {
		background: transparent;
	}

	.menus.open {
		height: 100vh;
	}

	.menus .menu {
		width: 95%;
		margin: 0;
		display: inline-block;
		text-align: left;
		font-size: $font-size-40;
		z-index: 100;
	}
	.menus .menu:first-child {
		margin-top: 30px;
	}
	.menu .selected {
		font-weight: bold;
	}

	.main-navigation {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 100vh;
		padding: 0;
		background: white;
		overflow: hidden;
		transition: all 0.2s linear;
	}
	.main-navigation.active {
		padding: 50px 20px 20px;
		bottom: 0;
		height: auto;
		overflow: scroll;
	}
	.active .qzr-menu {
		display: block;
	}
	#menu-btn {
		display: block;
	}
}
</style>
