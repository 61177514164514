var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{ref:"subscriptionDialog",attrs:{"fullscreen":"","hide-overlay":"","scrollable":"","transition":"dialog-bottom-transition"},model:{value:(_vm.subscriptionDialogOpen),callback:function ($$v) {_vm.subscriptionDialogOpen=$$v},expression:"subscriptionDialogOpen"}},[(_vm.page)?_c('div',{staticClass:"modal",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.onClickOutside.apply(null, arguments)}}},[_c('MainColumn',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"font-size-100 white--text mb-4"},[_vm._v(_vm._s(_vm.page.title))])]),_c('v-icon',{staticClass:"close-icon",attrs:{"color":"white"},on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.onClickOutside.apply(null, arguments)}}},[_vm._v(" mdi-close ")])],1),_c('div',{staticClass:"form",style:({
					'min-height': _vm.sent ? 0 : '100vh',
					'margin-top': _vm.sent ? '15vh' : 0,
					'padding-bottom': _vm.sent ? 0 : '10vh',
				})},[_c('v-row',{style:({
						'background-color': !_vm.sent ? _vm.colorsObject['all'] : _vm.colorsObject['childs'],
					})},[_c('v-col',{class:{
							'white--text': true,
							'font-size-20': !_vm.sent,
							'font-size-25': _vm.sent,
							infoColumn: true,
						},attrs:{"align-self":"center","cols":_vm.$vuetify.breakpoint.mobile || _vm.sent ? 10 : 6,"offset":"1"}},[_c('div',{class:{ 'text-center': _vm.sent }},[(_vm.sent)?_c('v-icon',{attrs:{"size":"40","color":"white"}},[_vm._v(" mdi-check ")]):_vm._e(),_vm._v(" "+_vm._s(!_vm.sent ? _vm.$t("preSubscriptionHeader") : "Grazie, abbiamo ricevuto tutto! Ci vediamo in biblioteca.")+" ")],1),(!_vm.sent)?_c('v-btn',{staticClass:"infoButton",attrs:{"name":"Scopri","depressed":""},on:{"click":function($event){return _vm.onServicesClick()}}},[_vm._v("Scopri i servizi")]):_vm._e()],1),(!_vm.sent && !_vm.$vuetify.breakpoint.mobile)?_c('v-col',{attrs:{"offset":"1","cols":"3","align-self":"center"}},[_c('div',{staticClass:"logoCard"},[_c('Logo',{staticClass:"logo"})],1)]):_vm._e()],1),_c('v-slide-y-reverse-transition',[(!_vm.sent)?_c('div',[_c('v-row',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-col',[_c('RenderBlocks',{attrs:{"blocks":_vm.page.blocksJSON}})],1)],1),_c('v-row',[_c('v-slide-y-reverse-transition',[(_vm.isSubmitButtonVisible)?_c('v-col',{attrs:{"offset":"1","cols":"10"}},[_c('v-checkbox',{attrs:{"label":_vm.gdprText},model:{value:(_vm.gdpr),callback:function ($$v) {_vm.gdpr=$$v},expression:"gdpr"}})],1):_vm._e()],1),_c('v-slide-y-reverse-transition',[(_vm.isSubmitButtonVisible)?_c('v-col',{attrs:{"offset":"1","cols":"10"}},[_c('v-checkbox',{attrs:{"label":_vm.digitalResourcesText},model:{value:(_vm.digitalResources),callback:function ($$v) {_vm.digitalResources=$$v},expression:"digitalResources"}})],1):_vm._e()],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-slide-y-reverse-transition',[(_vm.isSubmitButtonVisible)?_c('v-btn',{staticClass:"button white--text",attrs:{"color":_vm.colorsObject['all'],"disabled":!_vm.isSubscriptionFormValid,"text":""},on:{"click":_vm.onSubmitForm}},[_vm._v(_vm._s(_vm.$t("subscribe")))]):_vm._e()],1)],1)],1):_vm._e()])],1)],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }