<template>
	<v-menu
		ref="menu"
		v-model="menu"
		bottom
		transition="scroll-y-reverse-transition"
		min-width="290px"
		:close-on-content-click="false"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-text-field
				v-model="formattedDate"
				color="black"
				readonly
				v-bind="attrs"
				v-on="on"
				:label="field.label"
				:rules="rules || []"
			></v-text-field>
		</template>
		<v-date-picker
			ref="picker"
			:color="colorsObject['all']"
			v-model="infoSubscription[field.label]"
			:min="getMinDate()"
			:max="getMaxDate()"
			@change="onBirthdayChange"
		></v-date-picker>
	</v-menu>
</template>
<script>
export default {
	props: {
		field: {
			type: Object,
			required: true,
		},
		rules: {
			type: Array,
			required: false,
		},
	},
	data() {
		return {
			menu: false,
			formattedDate: null,
		};
	},
	watch: {
		menu(val) {
			val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
		},
	},
	methods: {
		formatDate(date) {
			if (!date) return null;
			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},
		formatSlug(slug) {
			return slug.replace(/-/g, "");
		},
		getMinDate() {
			if (this.field.slug === "data-di-scadenza") {
				return new Date().toISOString().substr(0, 10);
			} else {
				return "1920-01-01";
			}
		},
		getMaxDate() {
			if (this.field.slug === "data-di-scadenza") {
				return "2050-12-31";
			} else {
				return new Date().toISOString().substr(0, 10);
			}
		},
		isDate18orMoreYearsOld(year, month, day) {
			return new Date(parseInt(year, 10) + 18, parseInt(month, 10) - 1, parseInt(day, 10)) <= new Date();
		},
		onBirthdayChange(value) {
			if (this.field.slug === "data-di-nascita") {
				let date = value.split("-");
				let year = date[0];
				let month = date[1];
				let day = date[2];
				this.updateProperty({
					property: "isAdult",
					value: this.isDate18orMoreYearsOld(year, month, day),
				});
			}
			this.$refs.menu.save(value);
			this.formattedDate = this.formatDate(this.infoSubscription[this.field.label]);
		},
	},
};
</script>
