<template>
	<div class="container-cards background-gray">
		<MainColumn>
			<div class="container-left">
				<RenderBlocks v-if="blocksLeft" :blocks="blocksLeft" />
			</div>
			<div class="container-right">
				<RenderBlocks v-if="blocksRight" :blocks="blocksRight" />
			</div>
		</MainColumn>
	</div>
</template>

<script>
import MainColumn from "@/components/ui/MainColumn";

export default {
	props: ["block", "blocks", "parentBlock"],
	components: {
		MainColumn,
		RenderBlocks: () => import("@/components/blocks/RenderBlocks.vue")
	},
	data() {
		return {
			blocksLeft: [],
			blocksRight: [],
		};
	},
	mounted() {
		this.block.innerBlocks.map( (el,index) => {
			if(index%2 == 0) {
				this.blocksLeft.push(el);
			}
			else {
				this.blocksRight.push(el);
			}
		});
	}
};
</script>

<style lang="scss">
.lazyblock_container_cards {
	margin-bottom: -20px;
}
.container-cards {
	position: relative;
	padding-bottom: 50px;
	display: inline-block;
	width: 100%;
	.main-column {
		display: flex;
		flex-wrap: wrap;
	}
	.render-blocks {
		width: 100%;
		display: block;
	}

	.container-right,
	.container-left {
		display: inline-block;
		width: 50%;
		@media only screen and (max-width: 550px) {
			width: 100%;
		}
	}

}

.lazyblock_single_card {
	display: inline-block;
	width: 100%;
	float: left;
}

.lazyblock_single_card .content {
	background: #fff;
	color: #000;
	padding: 25px;
	margin: 15px;
	border-radius: 25px;
	border-bottom-left-radius: 0;
	box-shadow: 15px 15px 19px -19px rgba(0, 0, 0, 0.6);
}

@media only screen and (max-width: 550px) {
	.lazyblock_single_card {
		width: 100%;
		margin: 0;
	}
	.lazyblock_single_card:nth-child(even) {
		margin: 0;
	}

	.container-cards .single-card {
		width: 100%;
		margin: 0;
	}
}
</style>
