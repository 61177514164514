<template>
	<div class="gallery">
		<swiper
			class="swipe"
			ref="swiper"
			:options="swiperOption"
			@slideChange="onSlideChange"
		>
			<swiper-slide
				v-for="(image, i) in block.attributes.images"
				:key="i"
				class="slide"
				id="element-near-top"
			>
				<img :src="image.url" />
			</swiper-slide>
		</swiper>
		<div class="pagination">
			<PaginationSlideshow
				:currentIndex="currentIndex + 1"
				:numberOfPosts="block.attributes.images.length"
				target="white"
				@onPaginationClick="onPaginationClick"
			></PaginationSlideshow>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.slide {
	text-align: center;
}
.pagination {
	margin-bottom: 50px;
}
img {
	max-width: 100%;
	width: 100%;
	margin-top: $spacing-0;
	height: 50vh;
	object-fit: contain;
	@media only screen and (max-width: 768px) {
		height: auto;
	}
}
.gallery {
	position: relative;
}

.pagination {
	position: relative;
	margin-top: 25px;
}
</style>

<script>
import PaginationSlideshow from "@/components/ui/PaginationSlideshow.vue";

export default {
	name: "core_gallery",
	props: ["block"],
	components: {
		PaginationSlideshow
	},
	data() {
		return {
			currentIndex: 0,
			swiperOption: {
				slidesPerView: 1,
				spaceBetween: 150,
				centeredSlides: true,
				activeColor: null,
				autoHeight: true
			}
		};
	},
	computed: {
		swiper() {
			return this.$refs.swiper.$swiper;
		}
	},
	methods: {
		onSlideChange() {
			this.currentIndex = this.swiper.activeIndex;
		},
		onPaginationClick(index) {
			this.swiper.slideTo(index);
		}
	}
};
</script>
