<template>
	<div class="pagination">
		<div :class="'container'">
			<div
				:class="'spacer spacer-' + target"
				:style="{
					background:
						target === 'white' ||
						target == 'light-childs' ||
						target == 'light-schools' ||
						target == 'light-adults' ||
						target == 'light-family'
							? 'black'
							: 'white'
				}"
			></div>
			<transition-group tag="div">
				<div
					v-for="i in numberOfPosts"
					size="10"
					:class="
						'circle background-' +
							target +
							' ' +
							(target === 'white'
								? 'circle-black'
								: 'circle-white') +
							' ' +
							(currentIndex > i - 1 ? 'active' : '')
					"
					:key="i"
					:style="{
						left:
							currentIndex >= i ? (i - 1) * width + 'px' : 'auto',
						right:
							currentIndex < i
								? (numberOfPosts - i) * width + 'px'
								: 'auto'
					}"
					@click="onPaginationClick(i)"
				></div>
			</transition-group>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		currentIndex: {
			type: Number,
			required: true
		},
		numberOfPosts: {
			type: Number,
			required: true
		},
		target: {
			type: String,
			required: false
		}
	},
	data: () => ({
		width: 16
	}),
	methods: {
		onPaginationClick(index) {
			this.$emit("onPaginationClick", index - 1);
		}
	}
};
</script>
<style scoped>
.pagination {
	width: 100%;
	text-align: center;
}
.background-white {
	background: white;
}
.container {
	transition: 0.3s all;
	max-width: 300px;
	display: inline-block;
	position: relative;
	height: 12px;
	margin: 0 auto;
}

#app .circle {
	border-radius: 50%;
	padding: 3px 3px;
	z-index: 10;
	top: 0;
	position: absolute;
	cursor: pointer;
	border: 2px solid #fff;
	transition: 0.3s all ease-in-out;
}

#app .circle.active {
	background: #fff;
}
#app .active.circle-black,
#app .background-light-childs.circle.active,
#app .background-light-adults.circle.active,
#app .background-light-family.circle.active,
#app .background-light-all.circle.active,
#app .background-light-schools.circle.active {
	background: #000;
}
#app .circle-black,
#app .background-light-childs.circle,
#app .background-light-adults.circle,
#app .background-light-family.circle,
#app .background-light-all.circle,
#app .background-light-schools.circle {
	border-color: #000;
}

.spacer {
	border-radius: 10px;
	height: 2px;
	left: 2px;
	position: absolute;
	right: 2px;
	top: 5px;
	transform: translateY(-50%);
	z-index: 1;
}

.v-icon:focus::after {
	opacity: 0;
}

.circle.background-light-childs,
.circle.background-light-childs.active {
	box-shadow: 0px 0px 0px 5px #bfeddb;
}
.circle.background-light-adults,
.circle.background-light-adults.active {
	box-shadow: 0px 0px 0px 5px #f6b9b9;
}
.circle.background-light-childs,
.circle.background-light-childs.active {
	box-shadow: 0px 0px 0px 5px #bfeddb;
}
.circle.background-light-schools,
.circle.background-light-schools.active {
	box-shadow: 0px 0px 0px 5px #d5b8f3;
}
.circle.background-light-family,
.circle.background-light-family.active {
	box-shadow: 0px 0px 0px 5px #ffdaaa;
}
.circle.background-all,
.circle.background-all.active {
	box-shadow: 0px 0px 0px 5px #497dd6;
}
.circle.background-childs,
.circle.background-childs.active {
	box-shadow: 0px 0px 0px 5px #36bf88;
}
.circle.background-adults,
.circle.background-adults.active {
	box-shadow: 0px 0px 0px 5px #eb6a6a;
}
.circle.background-family,
.circle.background-family.active {
	box-shadow: 0px 0px 0px 5px #fdad47;
}
.circle.background-schools,
.circle.background-schools.active {
	box-shadow: 0px 0px 0px 5px #974fe0;
}

.circle.background-white {
	box-shadow: 0px 0px 0px 5px #fff;
}
</style>
