<template>
	<div>
		<div class="render-blocks" v-if="blocks">
			<div
				v-for="(block, i) in blocksParsed"
				:key="i"
				:class="{
					[block.name
						.split('/')
						.join('_')
						.split('-')
						.join('_')]: true,
					'paddin-bottom': padding,
				}"
			>
				<component
					v-bind:is="
						block.name
							.split('/')
							.join('_')
							.split('-')
							.join('_')
					"
					:block="block"
					:parentBlock="parentBlock ? parentBlock : false"
					:offset="offset"
					:padding="padding"
				/>
			</div>
		</div>
		<Loader v-else />
	</div>
</template>

<script>
import componentsBlocks from "@/components/blocks";
import Background from "@/components/ui/Background";
import Loader from "@/components/ui/Loader";
export default {
	name: "RenderBlocks",
	props: ["blocks", "parentBlock", "offset", "padding"],
	components: {
		Loader,
		Background,
		componentsBlocks: componentsBlocks,
		core_paragraph: () => import("@/components/blocks/core/core_paragraph.vue"),
		core_heading: () => import("@/components/blocks/core/core_heading.vue"),
		core_image: () => import("@/components/blocks/core/core_image.vue"),
		core_gallery: () => import("@/components/blocks/core/core_gallery.vue"),
		core_buttons: () => import("@/components/blocks/core/core_buttons.vue"),
		core_button: () => import("@/components/blocks/core/core_button.vue"),
		core_list: () => import("@/components/blocks/core/core_list.vue"),
		core_columns: () => import("@/components/blocks/core/core_columns.vue"),
		core_quote: () => import("@/components/blocks/core/core_quote.vue"),
		core_embed: () => import("@/components/blocks/core/core_embed_youtube.vue"),
		core_embed_youtube: () => import("@/components/blocks/core/core_embed_youtube.vue"),
		core_media_text: () => import("@/components/blocks/core/core_media_text.vue"),

		lazyblock_container_cards: () =>
			import("@/components/blocks/lazyblocks/lazyblock_container_cards.vue"),
		lazyblock_what_to_do: () =>
			import("@/components/blocks/lazyblocks/lazyblock_what_to_do.vue"),
		lazyblock_our_mission: () =>
			import("@/components/blocks/lazyblocks/lazyblock_our_mission.vue"),
		lazyblock_single_card: () =>
			import("@/components/blocks/lazyblocks/lazyblock_single_card.vue"),
		lazyblock_faq: () =>
			import("@/components/blocks/lazyblocks/lazyblock_faq.vue"),
		lazyblock_school_panel: () =>
			import("@/components/blocks/lazyblocks/lazyblock_school_panel.vue"),
		lazyblock_projects: () =>
			import("@/components/blocks/lazyblocks/lazyblock_projects.vue"),
		lazyblock_cards: () =>
			import("@/components/blocks/lazyblocks/lazyblock_cards.vue"),
		lazyblock_contacts: () =>
			import("@/components/blocks/lazyblocks/lazyblock_contacts.vue"),
		lazyblock_last_posts: () =>
			import("@/components/blocks/lazyblocks/lazyblock_last_posts.vue"),
		lazyblock_rules: () =>
			import("@/components/blocks/lazyblocks/lazyblock_rules.vue"),
		lazyblock_this_month: () =>
			import("@/components/blocks/lazyblocks/lazyblock_this_month.vue"),
		lazyblock_newsletter: () =>
			import("@/components/blocks/lazyblocks/lazyblock_newsletter.vue"),
		lazyblock_slideshow_fullpage: () =>
			import("@/components/blocks/lazyblocks/lazyblock_slideshow_fullpage.vue"),
		lazyblock_openingtime: () =>
			import("@/components/blocks/lazyblocks/lazyblock_openingtime.vue"),
		lazyblock_target_list: () =>
			import("@/components/blocks/lazyblocks/lazyblock_target_list.vue"),
		lazyblock_qzr_events_list: () =>
			import("@/components/blocks/lazyblocks/lazyblock_qzr_events_list.vue"),
		qzrstudio_panel: () =>
			import("@/components/blocks/qzr/qzrstudio_panel.vue"),
		qzrstudio_advancedform: () =>
			import("@/components/blocks/qzr/qzrstudio_advancedform.vue"),
	
	},

	computed: {
		blocksParsed() {
			let blocksParsed;

			if (typeof this.blocks == "string") {
				blocksParsed = JSON.parse(this.blocks);
			} else {
				blocksParsed = this.blocks;
			}

			return blocksParsed;
		},
	},
};
</script>

<style lang="scss" scoped>
.render-blocks {
	width: 100%;
}

.loading {
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100vh;
	width: 100vw;
}

.loading svg {
	position: absolute;
	height: 50vh;
	width: 50vw;
	left: 50vw;
	top: 50vh;
	transform: translate(-50%, -50%);
	z-index: 1000;
}
.padding-bottom {
	padding-bottom: $spacing-1;
}
</style>
