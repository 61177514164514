import { render, staticRenderFns } from "./Background.vue?vue&type=template&id=5701e8ce&scoped=true"
import script from "./Background.vue?vue&type=script&lang=js"
export * from "./Background.vue?vue&type=script&lang=js"
import style0 from "./Background.vue?vue&type=style&index=0&id=5701e8ce&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5701e8ce",
  null
  
)

export default component.exports