import gql from "graphql-tag";

function GET_PAGES_TITLE() {
	return gql`
		query GET_PAGES_TITLE {
			menu(idType: NAME, id: "qzr-menu") {
				id
				name
				menuItems {
					nodes {
						url
						label
						id
						order
						path
					}
				}
			}
		}
	`;
}

function GET_PAGE(slug) {
	return gql`
		query GET_PAGE {
			pages(where: {name: "${slug}"}) {
				nodes {
					seo {
						metaDesc
						metaKeywords
						title
						opengraphTitle
						opengraphSiteName
						opengraphPublisher
						opengraphType
						opengraphUrl
						opengraphPublishedTime
						opengraphModifiedTime
						opengraphImage {
						  id
						  uri
						  sourceUrl
						}
					  }
					id
					uri
					title
					blocksJSON
					children(where: {orderby: {order: ASC, field: TITLE}}) {
						nodes {
						  slug
						  ... on Page {
							id
							blocksJSON
							title
							uri
						  }
						}
					  }
				}
			  }
			
	}
	
	`;
}

function GET_QZR_TARGETS() {
	return gql`
		query GET_QZR_TARGETS {
			pages(where: { parent: 350, orderby: { field: MENU_ORDER, order: ASC } }) {
				nodes {
					title
					slug
				}
			}
		}
	`;
}

function GET_SLIDING_PAGES(slug) {
	return gql`
	query GET_SLIDING_PAGES {
		page(id: "${slug}", idType: URI) {
			seo {
				metaDesc
				metaKeywords
				title
				opengraphTitle
				opengraphSiteName
				opengraphPublisher
				opengraphType
				opengraphUrl
				opengraphPublishedTime
				opengraphModifiedTime
				opengraphImage {
				  id
				  uri
				  sourceUrl
				}
			  }
		id
		children(where: {orderby: {field: MENU_ORDER, order: ASC}}) {
		  nodes {
			... on Page {
			  id
			  title
			  blocksJSON
			  slug
			  seo {
				metaDesc
				metaKeywords
				title
				opengraphTitle
				opengraphSiteName
				opengraphPublisher
				opengraphType
				opengraphUrl
				opengraphPublishedTime
				opengraphModifiedTime
				opengraphImage {
				  id
				  uri
				  sourceUrl
				}
			  }
			  featuredImage {
				node {
				  mediaItemUrl
				}
			  }
			  terms {
				nodes {
				  ... on Qzr_target {
					id
					name
					slug
				  }
				}
			  }
			}
		  }
		}
	  }
	}
	`;
}

export { GET_PAGE, GET_PAGES_TITLE, GET_QZR_TARGETS, GET_SLIDING_PAGES };
