var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pagination"},[_c('div',{class:'container'},[_c('div',{class:'spacer spacer-' + _vm.target,style:({
				background:
					_vm.target === 'white' ||
					_vm.target == 'light-childs' ||
					_vm.target == 'light-schools' ||
					_vm.target == 'light-adults' ||
					_vm.target == 'light-family'
						? 'black'
						: 'white'
			})}),_c('transition-group',{attrs:{"tag":"div"}},_vm._l((_vm.numberOfPosts),function(i){return _c('div',{key:i,class:'circle background-' +
						_vm.target +
						' ' +
						(_vm.target === 'white'
							? 'circle-black'
							: 'circle-white') +
						' ' +
						(_vm.currentIndex > i - 1 ? 'active' : ''),style:({
					left:
						_vm.currentIndex >= i ? (i - 1) * _vm.width + 'px' : 'auto',
					right:
						_vm.currentIndex < i
							? (_vm.numberOfPosts - i) * _vm.width + 'px'
							: 'auto'
				}),attrs:{"size":"10"},on:{"click":function($event){return _vm.onPaginationClick(i)}}})}),0)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }