<template>
	<v-slide-y-reverse-transition>
		<v-form
			ref="form"
			v-if="isFormVisible(block.attributes.qzr_form_advanced_uuid, infoSubscription['Data di nascita'])"
			@input="onInputForm"
		>
			<v-container fluid>
				<v-row>
					<v-col offset="1">
						<div class="formTitle">
							{{ block.attributes.qzr_form_advanced_uuid.replace(/-/g, " ") | capitalize }}
						</div>
					</v-col>
				</v-row>
				<v-slide-y-reverse-transition group style="width: 100%" class="row" tag="div">
					<v-col
						v-for="(field, i) in fields"
						:cols="$vuetify.breakpoint.mobile ? 10 : 5"
						:class="{
							'py-0': true,
							fields: true,
							paddingRight: i % 2 === 0 && !$vuetify.breakpoint.mobile,
							paddingLeft: i % 2 !== 0 && !$vuetify.breakpoint.mobile,
						}"
						:key="field.slug"
						:offset="$vuetify.breakpoint.mobile ? 1 : i % 2 === 0 ? 1 : 0"
					>
						<v-text-field
							:id="field.label"
							:ref="field.label"
							v-model="infoSubscriptionForm[field.label]"
							v-if="isTextFieldVisible(formatSlug(field.slug), field.type)"
							color="black"
							:label="field.label"
							:rules="getRules(field)"
						></v-text-field>

						<DatePicker
							v-if="datePickerTypes.indexOf(field.type) > -1"
							:field="field"
							:rules="getRules(field)"
						/>

						<v-select
							v-model="infoSubscriptionForm[field.label]"
							v-if="selectFieldTypes.indexOf(field.type) > -1"
							:items="field.data"
							item-text="label"
							item-value="label"
							:label="field.label"
							>{{ field }}</v-select
						>
						<v-autocomplete
							v-if="field.type === 'municipalities'"
							v-model="infoSubscriptionForm[field.label]"
							color="black"
							hide-no-data
							hide-selected
							item-text="name"
							item-value="name"
							:label="field.label"
							:filter="filterMunicipalitiesByName"
							:items="municipalities"
							:rules="getRules(field)"
						></v-autocomplete>
					</v-col>
				</v-slide-y-reverse-transition>
			</v-container>
		</v-form>
	</v-slide-y-reverse-transition>
</template>

<script>
import DatePicker from "@/components/ui/DatePicker";
export default {
	props: ["block", "parentBlock"],
	components: {
		DatePicker,
	},
	data() {
		return {
			datePickerTypes: ["date"],
			fields: [],
			isDatePickNecessaryFormIDCondition: ["documento-d'identità", "documento-d'identità-di-un-genitore"],
			isAdultFormIDCondition: ["documento-d'identità"],
			isMinorFormIDCondition: ["documento-d'identità-di-un-genitore"],
			isAdultSlugCondition: ["professione", "titolodistudio"],
			menu: false,
			selectFieldTypes: ["select"],
			textFieldTypes: ["text", "email", "tel"],
		};
	},
	computed: {
		isMinor() {
			return !this.isAdult;
		},
		infoSubscriptionForm: {
			get() {
				return this.$store.state.infoSubscription;
			},
			set(value) {
				this.updateProperty({
					property: "infoSubscription",
					value: value,
				});
			},
		},
	},
	watch: {
		subscriptionDialog() {
			if (this.$refs.form) {
				this.$refs.form.reset();
			}
		},
	},
	async mounted() {
		await this.getMunicipalities();

		if (this.block.attributes.qzr_form_advanced_uuid === "dati-personali") {
			let mainEmail = JSON.parse(this.block.attributes.emails).filter((email) => email.main_email)[0];
			this.updateProperty({
				property: "emailForSubscribe",
				value: mainEmail.email,
			});
		}

		let loadedFields = JSON.parse(this.block.attributes.fields);
		loadedFields = loadedFields.filter(
			(element) => element.slug !== "email" || this.block.attributes.qzr_form_advanced_uuid === "dati-personali"
		);
		let numberOfFields = loadedFields.length;
		for (let i = 0; i < loadedFields.length; i += 2) {
			let field = loadedFields[i];
			let nextField = i !== loadedFields.length - 1 ? loadedFields[i + 1] : null;
			setTimeout(() => {
				this.fields.push(field);
				if (nextField) {
					this.fields.push(nextField);
				}
			}, 120 * i);
			setTimeout(() => {
				this.updateProperty({
					property: "isSubmitButtonVisible",
					value: true,
				});
			}, 120 * (numberOfFields + 1));
		}
	},
	methods: {
		filterMunicipalitiesByName(item, queryText) {
			const textOne = item.name.toLowerCase();
			const cap = item.cap;
			const searchText = queryText.toLowerCase();
			return textOne.indexOf(searchText) > -1 || cap.indexOf(searchText) > -1;
		},
		formatSlug(slug) {
			return slug.replace(/-/g, "");
		},
		async getMunicipalities() {
			if (!this.municipalities) {
				let municipalities = await this.$api.get("/frontend/comuni.json");
				municipalities = municipalities.data.map((municipality) => ({
					name: municipality.nome,
					coordinate: municipality.coordinate,
					cap: municipality.cap,
				}));
				this.updateProperty({
					property: "municipalities",
					value: municipalities,
				});
			}
		},
		getRules(field) {
			let rules = [];
			if (field.label === "CAP") {
				rules.push(this.validationRules.cap);
			}
			if (field.required) {
				rules.push(this.validationRules.required);
			}
			if (field.type === "email") {
				rules.push(this.validationRules.email);
			}
			return rules;
		},
		isFormVisible(id, birthdayDate) {
			let isFormVisible =
				(this.isDatePickNecessaryFormIDCondition.indexOf(id) === -1 || birthdayDate) &&
				(this.isAdultFormIDCondition.indexOf(id) === -1 || this.isAdult) &&
				(this.isMinorFormIDCondition.indexOf(id) === -1 || this.isMinor);
			if (!isFormVisible) {
				let currentSubscriptionFormList = this.subscriptionFormList;
				currentSubscriptionFormList[this.block.attributes.qzr_form_advanced_uuid] = true;
				this.updateProperty({
					property: "subscriptionFormList",
					value: currentSubscriptionFormList,
				});
			}
			return isFormVisible;
		},
		isTextFieldVisible(slug, type) {
			let additionalCondition = this.isAdultSlugCondition.indexOf(slug) === -1 || this.isAdult;
			return this.textFieldTypes.indexOf(type) > -1 && additionalCondition;
		},
		onInputForm(value) {
			let subscriptionFormList = this.subscriptionFormList;
			subscriptionFormList[this.block.attributes.qzr_form_advanced_uuid] = value;
			this.updateProperty({
				property: "subscriptionFormList",
				value: {},
			});
			this.updateProperty({
				property: "subscriptionFormList",
				value: subscriptionFormList,
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.form {
	margin-bottom: 20vh;
}
.formTitle {
	font-weight: bold;
	font-size: $font-size-25;
	padding-top: $spacing-0;
}
.paddingRight {
	padding-right: $spacing-0;
}
.paddingLeft {
	padding-left: $spacing-0;
}
</style>
