<template>
	<div class="newsletter">
		<Background backgroundColor="white" diameter="13" color="#D9D9D9">
			<MainColumn>
				<div class="newsletter-content">
					<v-card class="card" flat>
						<v-row>
							<v-col lg="4">
								<v-row>
									<h3 class="qzr-title font-size-35">
										Iscriviti alla Newsletter
									</h3>
								</v-row>
								<v-row
									><div class="subtitle font-size-20">
										Resta aggiornato su eventi in programma, le proposte di letture ed i servizi
										offerti dalla biblioteca
									</div></v-row
								>
							</v-col>
							<v-col cols="7" offset="1" align-self="center">
								<v-form v-if="!alert.visible" v-model="validForm">
									<v-row>
										<v-col cols="6" class="fields">
											<v-text-field
												v-model="info.first_name"
												class="input"
												label="Nome"
												:color="colorsObject.adults"
												:rules="[validationRules.required]"
											></v-text-field>
										</v-col>
										<v-col cols="6" class="fields">
											<v-text-field
												v-model="info.last_name"
												:color="colorsObject.childs"
												class="input"
												label="Cognome"
												:rules="[validationRules.required]"
											></v-text-field>
										</v-col>
									</v-row>
									<v-row>
										<v-col cols="6" class="fields">
											<v-text-field
												v-model="info.email"
												class="input"
												label="E-mail"
												:color="colorsObject.family"
												:rules="[validationRules.required, validationRules.email]"
											></v-text-field>
										</v-col>
										<v-col cols="6" class="fields">
											<v-select
												v-model="selected"
												class="input"
												item-value="id"
												item-text="name"
												:color="colorsObject.schools"
												:items="newsletters"
												:rules="[validationRules.required]"
												label="Quale newsletter desideri?"
											></v-select>
										</v-col>
									</v-row>
									<v-btn
										class="button white--text"
										color="black"
										:disabled="!validForm"
										:loading="loading"
										@click="subscribeToNewsletter"
										name="Newsletter"
										>Iscriviti</v-btn
									>
								</v-form>
								<v-slide-y-reverse-transition>
									<div class="alert" v-if="alert.visible">
										<v-icon :color="alert.color" size="40">
											{{ alert.icon }}
										</v-icon>
										{{ alert.message }}
									</div>
								</v-slide-y-reverse-transition>
							</v-col>
						</v-row>
					</v-card>
				</div>
			</MainColumn>
		</Background>
	</div>
</template>

<script>
import Background from "@/components/ui/Background.vue";
export default {
	props: ["block", "blocks", "parentBlock"],
	components: { Background },
	data() {
		return {
			alert: {
				color: "success",
				icon: "mdi-check",
				message: "Grazie per esserti iscritto!",
				visible: false,
			},
			info: {
				email: null,
				first_name: null,
				last_name: null,
				group_id: null,
			},
			loading: false,
			newsletters: [
				{
					name: "Bambini e ragazzi",
					id: ["10"],
				},
				{
					name: "Giovani e adulti",
					id: ["9"],
				},
				{
					name: "Entrambe le newsletters",
					id: ["10", "9"],
				},
			],
			selected: null,
			validForm: false,
		};
	},
	methods: {
		async subscribeToNewsletter() {
			this.loading = true;
			for(let groupId in this.selected) {
				this.info['group_id'] = this.selected[groupId];
				await this.$api.post("/wp-json/qzr-data/subscribe", this.info);
			}
			this.alert.visible = true;
			this.loading = false;
		},
	},
};
</script>

<style lang="scss" scoped>
.alert {
	width: 100%;
	text-align: center;
	font-size: $font-size-20;
}
.fields {
	padding-right: $spacing-0;
}
.newsletter-content {
	box-shadow: 17px 17px 35px -5px rgba(0, 0, 0, 0.2);
	max-width: 1024px !important;
	width: 95%;
	margin: auto;
}
.qzr-title {
	padding: 0;
	padding-bottom: $spacing-0;
}

.input {
	max-width: 100%;
	margin-left: 0%;
}

.card {
	padding: $spacing-1;
}
.newsletter {
	position: relative;
	padding: $spacing-1;
}

.button {
	text-transform: uppercase;
	letter-spacing: unset;
	margin-top: $spacing-0;
	font-size: $font-size-12;
	border-radius: 20px;
	border-top-left-radius: 0;
	transition: 0.3s all;
	margin-left: 25px;
	float: right;
}
@media only screen and (max-width: 1024px) {
	.row,
	.col {
		display: inline-block;
		width: 100% !important;
		margin: 0;
		flex: 100%;
		max-width: 100%;
	}
	.fields {
		padding: 0;
	}
}
@media only screen and (max-width: 768px) {
	.newsletter {
		padding: $spacing-1 $spacing-0;
	}
	.card {
		padding: 1.5rem;
	}
}
</style>
