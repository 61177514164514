var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape",style:({
		'border-color': _vm.colorsObject[_vm.target],
		top: _vm.top ? _vm.calculateSpace(_vm.top) : 'auto',
		bottom: _vm.bottom ? _vm.calculateSpace(_vm.bottom) : 'auto',
		left: _vm.left ? _vm.calculateSpace(_vm.left) : 'auto',
		right: _vm.right ? _vm.calculateSpace(_vm.right) : 'auto',
		width: _vm.calculateSpace(_vm.width),
		height: _vm.calculateSpace(_vm.height),
	})},[(_vm.type == 'circle')?_c('div',{staticClass:"circle",style:({
			width: _vm.calculateSpace(_vm.width),
			height: _vm.calculateSpace(_vm.height),
			'border-width':
				(_vm.$vuetify.breakpoint.mobile ? _vm.columnWidth / 2 : _vm.columnWidth) + 'px',
			'border-color': _vm.colorsObject[_vm.target],
		})}):_vm._e(),(_vm.type == 'dots')?_c('div',{staticClass:"dots",style:({
			width: _vm.calculateSpace(_vm.width),
			height: _vm.calculateSpace(_vm.height),
		})},[_c('div',{style:({
				'background-color': _vm.colorsObject[_vm.target],
				width: _vm.columnWidth + 'px',
				height: _vm.columnWidth + 'px',
				left: 0,
				top: 0,
			})}),_c('div',{style:({
				'background-color': _vm.colorsObject[_vm.target],
				width: this.columnWidth + 'px',
				height: this.columnWidth + 'px',
				right: 0,
				top: 0,
			})}),_c('div',{style:({
				'background-color': _vm.colorsObject[_vm.target],
				width: this.columnWidth + 'px',
				height: this.columnWidth + 'px',
				bottom: 0,
			})}),_c('div',{style:({
				'background-color': _vm.colorsObject[_vm.target],
				width: this.columnWidth + 'px',
				height: this.columnWidth + 'px',
				bottom: 0,
				right: 0,
			})})]):_vm._e(),(_vm.type == 'cross')?_c('div',{staticClass:"cross",style:({
			width: _vm.calculateSpace(_vm.width),
			height: _vm.calculateSpace(_vm.height),
		})},[_c('div',{style:({
				'background-color': _vm.colorsObject[_vm.target],
				width: _vm.columnWidth + 'px',
				height: _vm.columnWidth + 'px',
				left: '50%',
				transform: 'translateX(-50%)',
				top: 0,
			})}),_c('div',{style:({
				'background-color': _vm.colorsObject[_vm.target],
				width: this.columnWidth + 'px',
				height: this.columnWidth + 'px',
				right: 0,
				top: '50%',
				transform: 'translateY(-50%)',
			})}),_c('div',{style:({
				'background-color': _vm.colorsObject[_vm.target],
				width: this.columnWidth + 'px',
				height: this.columnWidth + 'px',
				left: '50%',
				transform: 'translateX(-50%)',
				bottom: 0,
			})}),_c('div',{style:({
				'background-color': _vm.colorsObject[_vm.target],
				width: this.columnWidth + 'px',
				height: this.columnWidth + 'px',
				left: 0,
				top: '50%',
				transform: 'translateY(-50%)',
			})})]):_vm._e(),(_vm.type == 'rect')?_c('div',{staticClass:"rect",style:({
			'border-width':
				(_vm.$vuetify.breakpoint.mobile ? _vm.columnWidth / 2 : _vm.columnWidth) + 'px',
			'border-color': _vm.colorsObject[_vm.target],
			width: _vm.calculateSpace(_vm.width),
			height: _vm.calculateSpace(_vm.height),
		})}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }