<template>
	<div>
		<svg
			width="100%"
			height="100%"
			:class="{ bg: true, ['background-' + target]: true, headerMargin: spacingTop }"
			:style="{ 'background-color': backgroundColor }"
		>
			<defs>
				<pattern
					:id="'pattern-dots-' + _uid"
					:x="-diameter"
					:y="-columnWidth / 2 - diameter"
					:width="columnWidth + 'px'"
					:height="columnWidth + 'px'"
					patternUnits="userSpaceOnUse"
				>
					<circle
						:id="'dot-' + _uid"
						:fill="
							loadingStatus && !loading
								? 'transparent'
								: target == 'light-childs'
								? '#36BF88'
								: target == 'light-adults'
								? '#EB6A6A'
								: target == 'light-schools'
								? '#924CD9'
								: target == 'light-family'
								? '#FDAD47'
								: color
								? color
								: '#DDDDDD'
						"
						:cx="diameter"
						:cy="diameter"
						:r="diameter / 2"
					/>
				</pattern>
				<rect
					:id="'rect' + _uid"
					x="-50%"
					y="0"
					width="100%"
					height="100%"
					:fill="'url(#pattern-dots-' + _uid + ')'"
				/>
			</defs>
			<use :xlink:href="'#rect' + _uid" x="50%" y="0" />
		</svg>
		<div class="slotVisible">
			<slot />
		</div>
	</div>
</template>

<script>
export default {
	props: {
		loading: {
			type: Boolean,
			required: false,
			default: false,
		},
		backgroundColor: {
			type: String,
			required: false,
		},
		spacingTop: {
			type: Boolean,
			required: false,
			default: false,
		},
		color: {
			type: String,
			required: false,
			default: "#f1f1f1",
		},
		diameter: {
			type: String,
			required: false,
			default: "4",
		},
		target: {
			type: String,
			required: false,
		},
	},
};
</script>
<style lang="scss" scoped>
.bg {
	z-index: 0;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	transition: 0.3s all;
}
.slotVisible {
	position: relative;
}
.headerMargin {
	margin-top: $menu-height;
}
@media only screen and (max-width: 1024px) {
	#app .bg {
		top: 0 !important;
	}
}
</style>
