var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('svg',{class:{ bg: true, ['background-' + _vm.target]: true, headerMargin: _vm.spacingTop },style:({ 'background-color': _vm.backgroundColor }),attrs:{"width":"100%","height":"100%"}},[_c('defs',[_c('pattern',{attrs:{"id":'pattern-dots-' + _vm._uid,"x":-_vm.diameter,"y":-_vm.columnWidth / 2 - _vm.diameter,"width":_vm.columnWidth + 'px',"height":_vm.columnWidth + 'px',"patternUnits":"userSpaceOnUse"}},[_c('circle',{attrs:{"id":'dot-' + _vm._uid,"fill":_vm.loadingStatus && !_vm.loading
							? 'transparent'
							: _vm.target == 'light-childs'
							? '#36BF88'
							: _vm.target == 'light-adults'
							? '#EB6A6A'
							: _vm.target == 'light-schools'
							? '#924CD9'
							: _vm.target == 'light-family'
							? '#FDAD47'
							: _vm.color
							? _vm.color
							: '#DDDDDD',"cx":_vm.diameter,"cy":_vm.diameter,"r":_vm.diameter / 2}})]),_c('rect',{attrs:{"id":'rect' + _vm._uid,"x":"-50%","y":"0","width":"100%","height":"100%","fill":'url(#pattern-dots-' + _vm._uid + ')'}})]),_c('use',{attrs:{"xlink:href":'#rect' + _vm._uid,"x":"50%","y":"0"}})]),_c('div',{staticClass:"slotVisible"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }