import "core-js/stable";
import "regenerator-runtime/runtime";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import axios from "axios";
import VueYoutube from "vue-youtube";
import vuetify from "./plugins/vuetify";
import VueI18n from "vue-i18n";
import titleMixin from "./mixins/titleMixin";
import { createProvider } from "./vue-apollo";
import { utilities } from "./mixins/utilities";
import { Swiper as SwiperClass, Pagination, Mousewheel, Autoplay } from "swiper/swiper.esm";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
SwiperClass.use([Pagination, Mousewheel, Autoplay]);
import "swiper/swiper-bundle.css";
import VueMeta from "vue-meta";

Vue.config.productionTip = false;

Vue.use(getAwesomeSwiper(SwiperClass));
Vue.use(VueI18n);
Vue.use(VueMeta);
Vue.use(VueYoutube);
Vue.mixin(utilities);
Vue.mixin(titleMixin);

Vue.use({
	install(Vue) {
		Vue.prototype.$api = axios.create({
			//baseURL: process.env.VUE_APP_BASE_ROUTER,
			baseURL: "https://bibliotecaagora.it/",
			withCredentials: true
		});
	}
});

router.beforeEach((to, from, next) => {
	window.scrollTo(0, 0);
	next();
});

new Vue({
	router,
	store,
	apolloProvider: createProvider(),
	vuetify,
	i18n,
	render: h => h(App)
}).$mount("#app");
