var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.blocks)?_c('div',{staticClass:"render-blocks"},_vm._l((_vm.blocksParsed),function(block,i){return _c('div',{key:i,class:{
				[block.name
					.split('/')
					.join('_')
					.split('-')
					.join('_')]: true,
				'paddin-bottom': _vm.padding,
			}},[_c(block.name
						.split('/')
						.join('_')
						.split('-')
						.join('_'),{tag:"component",attrs:{"block":block,"parentBlock":_vm.parentBlock ? _vm.parentBlock : false,"offset":_vm.offset,"padding":_vm.padding}})],1)}),0):_c('Loader')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }