<template>
	<div>
		<div class="contacts">
			<v-row>
				<v-col cols="5">
					<h3 class="font-size-25" v-html="block.attributes.name" />

					<p class="font-size-25">{{ block.attributes.address_label }}</p>
					<div class="field">
						<p class="font-size-16">{{ block.attributes.phone }}</p>
						<p class="font-size-16">{{ block.attributes.email }}</p>
					</div>
					<div v-if="infoOpeningTime" class="font-size-20" v-html="infoOpeningTime.description"></div>
				</v-col>
				<v-col cols="1"></v-col>
				<v-col cols="6">
					<GoogleMapLoader :enableSearch="true" :markers="selectedMarkers">
						<template slot-scope="{ google, map }">
							<GoogleMapMarker
								ref="googleMapMarkers"
								v-for="marker in selectedMarkers"
								:key="marker.id"
								:marker="marker"
								:google="google"
								:map="map"
							/>
						</template>
					</GoogleMapLoader>
				</v-col>
			</v-row>
			<div class="separator"></div>
			<div class="font-size-14 point">
				PUNTI PRESTITO DECENTRATI
			</div>
			<v-row class="rowOpening" v-for="(rent, i) in parseAttribute(block.attributes.rents)" :key="i">
				<v-col cols="4">
					<h4 class="font-size-16 mb-0">{{ rent.name }}</h4>
					{{ rent.address }}
				</v-col>
				<v-col offset="2" cols="6">
					<div v-html="rent.opening" />
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import GoogleMapLoader from "@/components/GoogleMap/GoogleMapLoader";
import GoogleMapMarker from "@/components/GoogleMap/GoogleMapMarker";

export default {
	props: ["block", "blocks", "parentBlock"],
	components: { GoogleMapLoader, GoogleMapMarker },
	data() {
		return {
			info: "",
			selectedMarkers: [],
		};
	},
	mounted() {
		this.selectedMarkers.push({
			position: this.parseAttribute(this.block.attributes.coords).results[0].geometry.location,
			label: "Biblioteca Agorà",
			id: "Biblioteca Agorà",
		});
	},
};
</script>

<style lang="scss" scoped>
.calendar {
	padding: 0 !important;
	margin-top: 1rem;
}
.contacts {
	background: #fff;
	min-height: 100vh;
	word-break: break-word;
	padding: $spacing-1;
}
.description {
	margin: 20px 0;
}

.field {
	margin: $spacing-0 0;
}
p {
	margin: 0;
}
.rent {
	display: inline-block;
}
.rowOpening {
	margin-bottom: $spacing-0;
}
.separator {
	width: 100%;
	height: 2px;
	background: #000;
	margin: $spacing-1 0 $spacing-0 0;
}
.point {
	margin-bottom: $spacing-0;
}

h4 {
	margin-bottom: 1rem;
}
li {
	border-bottom: 2px solid #000 !important;
}

@media only screen and (max-width: 768px) {
	.col,
	.row {
		display: inline-block;
		width: 100%;
		max-width: 100%;
	}
	.contacts {
		padding: $spacing-0;
	}
}
</style>
