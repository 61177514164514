<template>
	<div class="loader">
		<div class="loader-1" v-if="template == 'tiny'">
			<MainColumn>
				<transition name="fade">
					<v-progress-circular
						v-show="status"
						:class="'asset background-' + background"
						indeterminate
						color="white"
					></v-progress-circular>
				</transition>
			</MainColumn>
		</div>
		<transition name="fade">
			<div class="loader-2" v-if="loadingStatus">
				<!-- <div class="loader-2" v-if="loadingStatus"> -->
				<Background :loading="true">
					<MainColumn class="wrapper">
						<Shape
							:left="centerX - 2 + Math.sin(timer) * offset"
							:top="centerX - 2 + Math.cos(timer) * offset"
							:width="$vuetify.breakpoint.mobile ? 2 : 4"
							:height="$vuetify.breakpoint.mobile ? 2 : 4"
							type="rect"
							target="childs"
						/>
						<Shape
							:left="centerX - 2 + Math.sin(timer + Math.PI / 2) * offset"
							:top="centerX - 2 + Math.cos(timer + Math.PI / 2) * offset"
							:width="$vuetify.breakpoint.mobile ? 2 : 4"
							:height="$vuetify.breakpoint.mobile ? 2 : 4"
							type="dots"
							target="all"
						/>
						<Shape
							:left="centerX - 2 + Math.sin(timer + Math.PI) * offset"
							:top="centerX - 2 + Math.cos(timer + Math.PI) * offset"
							:width="$vuetify.breakpoint.mobile ? 2 : 4"
							:height="$vuetify.breakpoint.mobile ? 2 : 4"
							type="circle"
							target="adults"
						/>
						<Shape
							:left="centerX - 2 + Math.sin(timer + (Math.PI / 2) * 3) * offset"
							:top="centerX - 2 + Math.cos(timer + (Math.PI / 2) * 3) * offset"
							:width="$vuetify.breakpoint.mobile ? 2 : 4"
							:height="$vuetify.breakpoint.mobile ? 2 : 4"
							type="cross"
							target="family"
						/>
					</MainColumn>
				</Background>
			</div>
		</transition>
		<transition name="fade">
			<div class="loader-3" v-if="template == 'tiny-fullpage'" v-show="status">
				<v-progress-circular
					:class="'asset background-' + background"
					indeterminate
					:color="color"
				></v-progress-circular>
			</div>
		</transition>
	</div>
</template>
<script>
import Background from "@/components/ui/Background";
import Shape from "@/components/ui/Shape";
export default {
	components: {
		Background,
		Shape,
	},
	props: ["template", "status", "background"],
	data() {
		return {
			color: "#fff",
			timer: 0,
		};
	},
	computed: {
		centerX() {
			return Math.round(
				parseInt(this.calculateWidthColumn(this.mainColumnWidth)) /
					this.columnWidth /
					2
			);
		},
		centerY() {
			return Math.round(this.dotsNumberY / 2);
		},
		offset() {
			return this.$vuetify.breakpoint.mobile
				? 2
				: Math.max(4, Math.ceil(this.dotsNumberX / 10));
		},
	},
	mounted() {
		/* console.log("offset: " + this.offset);
		console.log("centerX: " + this.centerX);
		console.log("centerY: " + this.centerY); */

		setInterval(
			function() {
				this.timer += Math.PI / 8;
			}.bind(this),
			100
		);

		let colors = ["#36BF88", "#FDAD47", "#974FE0", "#497DD6", "#EB6A6A"];
		setInterval(
			function() {
				this.color = colors[Math.floor(Math.random() * colors.length)];
			}.bind(this),
			500
		);
	},
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}

.loader-1 .asset {
	position: absolute;
	bottom: 18px;
	right: 5%;
	transition: 0.3s all;
	z-index: 9999;
}
.loader-2 {
	background: rgba(255, 255, 255, 0.7);
	z-index: 9999;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 150vh;
	width: 100vw;
}

.loader-2 svg {
	position: absolute;
	height: 50vh;
	width: 50vw;
	left: 50vw;
	top: 50vh;
	transform: translate(-50%, -50%);
	z-index: 1000;
}

.loader-3 {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	background: rgba(255, 255, 255, 0.5);
	bottom: 0;
}

.loader-3 .asset {
	position: absolute;
	bottom: 1vh;
	right: 1vh;
}

@media only screen and (max-width: 1024px) {
	.wrapper {
		top: calc((100vh - 100vw) / 2);
	}
}
</style>
