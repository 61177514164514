<template>
	<div></div>
</template>
<script>
export default {
	props: {
		google: {
			type: Object,
			required: true,
		},
		map: {
			type: Object,
			required: true,
		},
		marker: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			markerInstance: null,
		};
	},
	mounted() {
		const infowindow = new this.google.maps.InfoWindow({
			content: "<p>" + this.marker.label + "</p>",
		});
		this.markerInstance = new this.google.maps.Marker({
			position: this.marker.position,
			marker: this.marker,
			title: this.marker.label,
			animation: this.google.maps.Animation.DROP,
			map: this.map,
		});
		this.markerInstance.addListener("click", () => {
			infowindow.open(this.map, this.markerInstance);
		});
	},
	methods: {
		destroyMarker() {
			this.markerInstance.setMap(null);
		},
	},
};
</script>