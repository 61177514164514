<template>
	<Button
		:href="block.attributes.url"
		:value="block.attributes.text"
		:name="block.attributes.text"
	/>
</template>

<style lang="scss" scoped></style>

<script>
import Button from "@/components/ui/Button.vue";

export default {
	name: "core_button",
	props: ["block"],
	components: {
		Button,
	},
};
</script>
