var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logoWrapper",style:({
		width: _vm.calculateWidthColumn(_vm.width),
		'grid-template-columns': 'repeat(' + _vm.width + ',' + this.columnWidth + 'px)',
		'grid-template-rows': 'repeat(4,' + this.columnWidth + 'px)',
	})},_vm._l((_vm.logo),function(char,i){return _c('h2',{key:i,staticClass:"move logoChar text-center",style:({
			'background-color': 'transparent',
			'line-height': _vm.columnWidth + 'px',
			'grid-column': _vm.offset ? _vm.offset + _vm.logoPositions[i].left : _vm.logoPositions[i].left,
			'grid-row': _vm.logoPositions[i].top,
			'text-align': 'center',
			color: _vm.color || 'white',
			width: '100%',
		})},[_vm._v(" "+_vm._s(char)+" ")])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }