var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"article-card"},[(_vm.mainCategory.slug === 'recensioni')?_c('div',{class:{
			'bottom-align': _vm.bottomAlign,
			'review-card': _vm.reviewCard,
			review: true,
			header: true
		}},[_c('v-slide-y-reverse-transition',[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"image",attrs:{"alt":'Immagine ' + _vm.target.name,"height":"auto","src":_vm.image},on:{"load":function($event){_vm.show = true}}})]),_c('v-fade-transition',[(_vm.subCategory)?_c('CategoryShape',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],attrs:{"whiteText":_vm.whiteText,"category":_vm.subCategory.slug,"target":_vm.target.slug}}):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.show),expression:"!show"}],staticClass:"placeholder"})],1):_c('div',{class:{
			'bottom-align': _vm.bottomAlign,
			header: true,
			notReview: true
		}},[_c('ImageShape',{staticClass:"image notReview",attrs:{"category":_vm.mainCategory.slug,"srcImage":_vm.image,"imageSizes":_vm.imageSizes}})],1),(_vm.isSubheaderVisible)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.mainCategory.name)+_vm._s(_vm.subCategory ? " " + _vm.subCategory.name : ""))]),_c('span',{staticClass:"separator"},[_vm._v("•")]),_c('span',{class:_vm.whiteText ? 'text--white' : 'target-' + _vm.target.slug},[_vm._v(_vm._s(_vm.target.name))]),_c('h3',{staticClass:"font-size-25"},[_vm._v(_vm._s(_vm.title))]),(_vm.subtitle)?_c('h4',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.subtitle))]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }