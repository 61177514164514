<template>
	<v-dialog
		ref="contactsDialog"
		v-model="contactsDialogOpen"
		fullscreen
		hide-overlay
		transition="dialog-bottom-transition"
		scrollable
	>
		<div class="modal" v-if="page != null" @click.self="onClickOutside">
			<MainColumn>
				<div class="content">
					<v-row>
						<v-col>
							<v-row>
								<v-col cols="9">
									<h2 class="font-size-100 white--text mb-4">
										{{ page.title }}
									</h2>
								</v-col>

								<v-icon class="close-icon" color="white" @click.self="onClickOutside">
									mdi-close
								</v-icon>
							</v-row>
							<RenderBlocks :blocks="page.blocksJSON" />
						</v-col>
					</v-row>
				</div>
			</MainColumn>
		</div>
	</v-dialog>
</template>

<script>
import { GET_PAGE } from "@/queries/Pages.js";
import RenderBlocks from "@/components/blocks/RenderBlocks";
export default {
	props: ["slug", "fromHeader"],
	components: { RenderBlocks },
	data() {
		return {
			blocks: [],
			page: null,
		};
	},
	async mounted() {
		let query = {
			query: GET_PAGE(this.slug),
		};

		this.data = await this.$apollo.query(query);
		this.page = this.data.data.pages.nodes[0];

		if (!this.infoOpeningTime) {
			let response = await this.$api.get("/wp-json/qzrstudio/v1/openingtime");
			this.updateProperty({
				property: "infoOpeningTime",
				value: response.data,
			});
		}
	},
	computed: {
		contactsDialogOpen: {
			get: function() {
				return this.contactsDialog;
			},
			set: function(newValue) {
				this.updateProperty({
					property: "contactsDialog",
					value: newValue,
				});
			},
		},
	},
	methods: {
		onClickOutside() {
			if (this.fromHeader) {
				this.$router.go(-1);
			} else {
				history.pushState({}, null, process.env.VUE_APP_BASE_ROUTER);
			}
			this.updateProperty({
				property: "currentRoute",
				value: {
					path: "",
				},
			});
			this.updateProperty({
				property: "contactsDialog",
				value: false,
			});
			this.sent = false;
		},
	},
};
</script>

<style lang="scss" scoped>
h2 {
	color: #fff;
}
.modal {
	background: rgba(0, 0, 0, 0.8) !important;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	min-height: 100%;
	padding-top: 100px;
}
.content {
	margin-bottom: 10vh;
}
.close-icon {
	position: absolute;
	top: 90px;
	right: 0;
	z-index: 999;
}
@media only screen and (max-width: 1024px) {
	.close-icon {
		top: 74px;
	}
}

@media only screen and (max-width: 768px) {
	.close-icon {
		top: 44px;
	}
}
@media only screen and (max-width: 550px) {
	.close-icon {
		top: 28px;
	}
}
@media only screen and (max-width: 480px) {
	.close-icon {
		top: 12px;
	}
}
</style>
