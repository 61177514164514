<template>
	<div class="events">
		<MainColumn>
			<div
				v-for="event in events"
				:key="event.uid"
				class="event"
				:class="{
					['border-' + event.tag]: event.tag,
					'border-blue': !event.tag,
					[event.status]: true
				}">
				<div class="body">
					<div class="date font-size-25" v-html="event.result" />
					<div class="metadata tiny-uppercase">
						<div class="time" v-if="!event.timeEnd && event.timeStart">
							<b>{{ event.timeStart }}</b>
						</div>
						<div class="time" v-if="event.timeStart && event.timeEnd">
							<b>{{ event.timeStart }}/{{ event.timeEnd }}</b>
						</div>
						<div class="location">
							{{ event.location }} -
							<!--  {{event.geolocation.address}} {{event.geolocation.streetNumber}} -->
							<span v-if="event.category" :class="'color-' + event.tag">
								{{ event.category }}
							</span>
						</div>
						<div class="category"></div>
					</div>
					<h2 class="font-size-25">{{ event.title }}</h2>
					<div>
						<v-expansion-panels flat>
							<v-expansion-panel flat class="description">
								<v-expansion-panel-header flat>
									<div class="tiny-uppercase" :class="event.tag ? 'color' + event.tag : 'color-all'">
										Descrizione
									</div>
									<template v-slot:actions>
										<v-icon :color="colorsObject[event.tag]"> mdi-plus </v-icon>
									</template>
								</v-expansion-panel-header>
								<v-expansion-panel-content
									class="rule-content"
									:class="event.tag ? `background-light-${event.tag}` : 'background-light-blue'">
									<div v-html="event.description" class="font-weight-regular" />
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</div>
				</div>
				<div class="go-to-event" v-if="event.status == 'active'">
					<Button
						v-if="event.link"
						target="_blank"
						value="Visita"
						:href="event.link"
						:class="event.tag ? `background-${event.tag}` : 'background-blue'" />
					<Button
						v-else
						target="_blank"
						value="Visita"
						:href="'https://cultura.comune.lucca.it/detail/' + event.uid"
						:class="event.tag ? `background-${event.tag}` : 'background-blue'" />
					<!-- <div class="tiny-uppercase" :class="event.tag? `color-${event.tag}` : 'color-all'">{{event.available}} Posti</div> -->
				</div>
			</div>
		</MainColumn>
	</div>
</template>

<script>
	import Button from "@/components/ui/Button";

	export default {
		props: ["block", "blocks", "parentBlock"],
		components: { Button },
		data() {
			return {
				events: []
			};
		},
		methods: {
			addZero(i) {
				if (i < 10) {
					i = "0" + i;
				}
				return i;
			}
		},
		async mounted() {
			let response = await this.$api.post("/wp-json/qzrstudio/v1/events");
			this.events = response.data;
			const months = {
				0: "Gennaio",
				1: "Febbraio",
				2: "Marzo",
				3: "Aprile",
				4: "Maggio",
				5: "Giugno",
				6: "Luglio",
				7: "Agosto",
				8: "Settembre",
				9: "Ottobre",
				10: "Novembre",
				11: "Dicembre"
			};
			this.events.map(el => {
				let startDate, endDate, startDateObj, endDateObj;

				el.hashtags.map(tag => {
					switch (tag) {
						case "#bambini e famiglie":
						case "#bambini-e-famiglie":
						case "#bambiniefamiglie":
							el["category"] = "Bambini e famiglie";
							el["tag"] = "family";
							break;

						case "#ragazzi":
							el["category"] = "Ragazzi";
							el["tag"] = "childs";
							break;

						case "#giovani e adulti":
						case "#giovani-e-adulti":
						case "#giovanieadulti":
							el["category"] = "Giovani e adulti";
							el["tag"] = "adults";
							break;

						case "#scuole":
						case "#scuola":
							el["category"] = "Scuole";
							el["tag"] = "schools";
							break;

						default:
							el["category"] = "Per tutti";
							el["tag"] = "all";
					}
				});
				el["available"] = 0;
				el["timeStart"] = false;
				el["timeEnd"] = false;

				if (el.actSlotTickets && el.actSlotTickets.length > 0) {
					startDateObj = new Date(el.actSlotTickets[0].startSlot);
					endDateObj = new Date(el.actSlotTickets[el.actSlotTickets.length - 1].endSlot);
					el.actSlotTickets.map(ticket => {
						el["available"] = ticket.availableTickets + el["available"];
					});
				} else {
					startDateObj = new Date(el.startDate);

					startDate = {
						year: startDateObj.getUTCFullYear(),
						month: startDateObj.getUTCMonth(),
						day: startDateObj.getUTCDate(),
						hours: startDateObj.getUTCHours(),
						minutes: startDateObj.getUTCMinutes()
					};

					if (!el.endDate) {
						let today = new Date();
						if (
							startDate.year == today.getUTCFullYear() &&
							startDate.month == today.getUTCMonth() &&
							startDate.day == today.getUTCDate()
						) {
							el["status"] = "active";
						} else {
							el["status"] = "ended";
						}

						el["timeStart"] = this.addZero(startDate.hours) + ":" + this.addZero(startDate.minutes);
						el["timeEnd"] = "";
						el["startTimestamp"] = startDateObj.getTime();
						el["result"] = "<b>" + startDate.day + " " + months[startDate.month] + "</b>";
						return;
					}
					endDateObj = new Date(el.endDate);
				}

				startDate = {
					year: startDateObj.getUTCFullYear(),
					month: startDateObj.getUTCMonth(),
					day: startDateObj.getUTCDate(),
					hours: startDateObj.getUTCHours(),
					minutes: startDateObj.getUTCMinutes()
				};

				endDate = {
					year: endDateObj.getUTCFullYear(),
					month: endDateObj.getUTCMonth(),
					day: endDateObj.getUTCDate(),
					hours: endDateObj.getUTCHours(),
					minutes: endDateObj.getUTCMinutes()
				};

				let today = new Date();
				el["status"] = (endDateObj.getTime() - today.getTime()) / 1000 > 0 ? "active" : "ended";

				if (startDate.year == endDate.year) {
					if (startDate.month == endDate.month) {
						if (startDate.day == endDate.day) {
							el["timeStart"] = this.addZero(startDate.hours) + ":" + this.addZero(startDate.minutes);
							el["timeEnd"] = this.addZero(endDate.hours) + ":" + this.addZero(endDate.minutes);

							el["result"] = "<b>" + startDate.day + " " + months[endDate.month] + "</b>";
						} else {
							el["result"] =
								"Dal <b>" +
								startDate.day +
								"</b> al <b>" +
								endDate.day +
								" " +
								months[endDate.month] +
								"</b>";
						}
					} else {
						el["result"] =
							"Dal <b>" +
							startDate.day +
							" " +
							months[startDate.month] +
							"</b> al <b>" +
							endDate.day +
							" " +
							months[endDate.month] +
							"</b>";
					}
				} else {
					el["result"] =
						"Dal <b>" +
						startDate.day +
						" " +
						months[startDate.month] +
						" " +
						startDate.year +
						"</b> al <b>" +
						endDate.day +
						" " +
						months[endDate.month] +
						"/" +
						endDate.year +
						"</b>";
				}
				el["startTimestamp"] = startDateObj.getTime();
			});

			this.events.sort((a, b) => {
				if (!a.startTimestamp) {
					return 1;
				}
				if (!b.startTimestamp) {
					return -1;
				}

				return a.startTimestamp < b.startTimestamp ? 1 : -1;
			});
		}
	};
</script>

<style lang="scss" scoped>
	.events {
		margin: 50px 0;
		margin-bottom: 100px;
		.event {
			&.ended {
				opacity: 0.5;
			}
			display: flex;
			@media only screen and (max-width: 550px) {
				display: inline-block;
			}
			h2 {
				font-weight: normal;
			}
			.location {
				font-weight: normal;
			}
			margin: 25px 0;
			border-radius: 25px;
			overflow: hidden;
			box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1);
			border-left: 25px solid;

			&.border-all,
			&.border-blue {
				border-color: $blue;
			}

			&.border-adults,
			&.border-red {
				border-color: $red;
			}
			&.border-childs,
			&.border-green {
				border-color: $green;
			}
			&.border-family,
			&.border-orange {
				border-color: $orange;
			}
			&.border-schools,
			&.border-purple {
				border-color: $purple;
			}

			&.border-gray {
				border-color: $gray;
			}

			&.border-pink {
				border-color: $pink;
			}

			.body {
				@media only screen and (max-width: 550px) {
					width: 100%;
				}
				padding: 25px;
				padding-bottom: 0;
				float: left;
				width: calc(100% - 200px);

				.metadata {
					width: 100%;
					margin: 10px 0;
					display: flex;
					& > div:first-child {
						margin-right: 5px;
					}
				}

				.description {
					i {
						position: absolute;
						left: 0;
						top: 12px;
					}
					* {
						transition: all 0.3s;
					}

					.v-expansion-panel-content {
						width: calc(100% + 250px);
						@media only screen and (max-width: 550px) {
							width: calc(100% + 50px);
						}
						padding-left: 25px;
						margin-left: -25px;
						transition: all 0.3s;
						.font-weight-regular {
							max-width: 700px;
						}
					}
					.v-expansion-panel-header--active + .v-expansion-panel-content {
						padding-top: 25px;
						margin-bottom: -25px;
						@media only screen and (max-width: 550px) {
							margin-bottom: 25px;
						}
						padding-bottom: 25px;
					}
					.v-expansion-panel-header--active {
						padding-bottom: 0;
					}
					.v-expansion-panel-header--active i {
						top: 27px;
					}
				}
			}

			.tiny-uppercase {
				font-size: 0.8rem;
			}

			.go-to-event {
				@media only screen and (max-width: 550px) {
					width: 100%;
					padding-right: 25px;
					margin-bottom: 25px;
					text-align: right;
					.tiny-uppercase {
						margin: 10px 0;
					}
				}
				margin-top: 50px;
				padding-right: 10px;
				width: 200px;
				text-align: center;
				div {
					width: 100%;
				}
			}
		}
	}
</style>
