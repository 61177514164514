<template>
	<div
		class="logoWrapper"
		:style="{
			width: calculateWidthColumn(width),
			'grid-template-columns': 'repeat(' + width + ',' + this.columnWidth + 'px)',
			'grid-template-rows': 'repeat(4,' + this.columnWidth + 'px)',
		}"
	>
		<h2
			v-for="(char, i) in logo"
			:key="i"
			class="move logoChar text-center"
			:style="{
				'background-color': 'transparent',
				'line-height': columnWidth + 'px',
				'grid-column': offset ? offset + logoPositions[i].left : logoPositions[i].left,
				'grid-row': logoPositions[i].top,
				'text-align': 'center',
				color: color || 'white',
				width: '100%',
			}"
		>
			{{ char }}
		</h2>
	</div>
</template>

<script>
export default {
	data() {
		return {
			logo: "AGORÀ",
		};
	},
	props: {
		color: {
			type: String,
			required: false,
		},
		logoPositions: {
			type: Array,
			required: false,
			default: function() {
				return [
					{
						left: 0,
						top: 2,
						min: { x: 0, y: 2 },
						max: { x: 1, y: 2 },
					},
					{
						left: 2,
						top: 2,
						min: { x: 2, y: 2 },
						max: { x: 3, y: 2 },
					},
					{
						left: 4,
						top: 2,
						min: { x: 4, y: 2 },
						max: { x: 5, y: 2 },
					},
					{
						left: 6,
						top: 2,
						min: { x: 6, y: 2 },
						max: { x: 7, y: 2 },
					},
					{
						left: 8,
						top: 2,
						min: { x: 8, y: 2 },
						max: { x: 8, y: 2 },
					},
				];
			},
		},
		offset: {
			type: Number,
			required: false,
			default: 0,
		},
		target: {
			type: String,
			required: false,
		},
		width: {
			type: Number,
			required: true,
		},
	},
	mounted() {
		setInterval(this.moveLogo.bind(this), 2000);
	},
	methods: {
		moveLogo() {
			for (let i in this.logoPositions) {
				let plusOrMinusX = Math.random() < 0.5 ? -1 : 1;
				let plusOrMinusY = Math.random() < 0.5 ? -1 : 1;
				let left = this.logoPositions[i].left;
				var numberLeft = this.limitNumberWithinRange(
					left + plusOrMinusX,
					this.logoPositions[i].min.x,
					this.logoPositions[i].max.x
				);
				this.logoPositions[i].left = numberLeft;

				let top = this.logoPositions[i].top;
				var numberTop = this.limitNumberWithinRange(
					top + plusOrMinusY,
					this.logoPositions[i].min.y,
					this.logoPositions[i].max.y
				);
				this.logoPositions[i].top = numberTop;
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.background-default {
	background-color: white;
}
.logoWrapper {
	position: absolute;
	right: 0;
	top: 0;
	display: inline-grid;
	height: 100%;
}
.logoChar {
	font-size: $font-size-20;
	font-weight: normal;
	line-height: 0.9;
	transform: translate(50%, 50%);
	position: relative;
	left: 50%;
	top: 50%;
}
h2 {
	transition: all 0.3s;
}
@media only screen and (max-width: 550px) {
	.logoWrapper {
		display: none;
	}
}
</style>
