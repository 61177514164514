<template>
	<div :class="whiteText ? 'white' : target">
		<svg
			v-if="category == 'musica'"
			xmlns="http://www.w3.org/2000/svg"
			width="229"
			height="265"
			viewBox="0 0 229 265"
		>
			<path
				data-name="Poligono 17"
				d="M100.474,55.35c14.249-24.627,49.8-24.627,64.051,0L232.87,173.47c14.272,24.667-3.527,55.53-32.026,55.53H64.155c-28.5,0-46.3-30.863-32.026-55.53Z"
				transform="translate(229) rotate(90)"
			/>
		</svg>

		<svg
			v-if="category == 'film'"
			xmlns="http://www.w3.org/2000/svg"
			width="211"
			height="211"
			viewBox="0 0 211 211"
		>
			<path
				data-name="Sottrazione 5"
				d="M105.5,211A105.528,105.528,0,0,1,64.435,8.291a105.527,105.527,0,0,1,82.131,194.419A104.838,104.838,0,0,1,105.5,211Zm0-137.15a31.65,31.65,0,1,0,22.379,9.27A31.443,31.443,0,0,0,105.5,73.85Z"
			/>
		</svg>
		<svg
			v-if="category == 'libri'"
			xmlns="http://www.w3.org/2000/svg"
			width="222.813"
			height="222.813"
			viewBox="0 0 222.813 222.813"
		>
			<rect
				data-name="Rettangolo 344"
				width="236.329"
				height="78.776"
				transform="translate(222.813 55.703) rotate(135)"
			/>
		</svg>
	</div>
</template>

<script>
export default {
	name: "CategoryShape",
	props: ["target", "category", "whiteText"]
};
</script>

<style lang="scss" scoped>
.all svg {
	fill: $blue;
}
.adults svg {
	fill: $red;
}
.family svg {
	fill: $orange;
}
.childs svg {
	fill: $green;
}
.white svg {
	fill: white;
}
.schools svg {
	fill: $purple;
}

svg {
	position: absolute;
	height: 70%;
	width: 70%;
	right: 5%;
	top: 50%;
	transform: translateY(-50%);
}
</style>
