<template>
	<div
		:id="block.attributes.name"
		:class="{
			'single-card': true,
			'background-all': block.attributes.target == 'all',
			'background-adults': block.attributes.target == 'adults',
			'background-childs': block.attributes.target == 'childs',
			'background-family': block.attributes.target == 'family',
			card: true
		}"
	>
		<img v-if="block.attributes.image && parseAttribute(block.attributes.image).url" :src="parseAttribute(block.attributes.image).url" />
		<h3 class="font-size-35">{{ block.attributes.title }}</h3>
		<swiper
			class="swipe"
			ref="swiper"
			:options="swiperOption"
			@slideChange="onSlideChange"
		>
			<swiper-slide
				class="slide"
				v-for="(content, i) in contents"
				:key="i"
			>
				<div class="content">
					<h4 class="font-size-25" v-if="content.title_card">
						{{ content.title_card }}
					</h4>
					<div v-html="content.content_card"></div>
				</div>
			</swiper-slide>
		</swiper>
		<PaginationSlideshow
			class="pagination"
			:target="block.attributes.target"
			:currentIndex="currentIndex + 1"
			:numberOfPosts="numberOfPosts"
			@onPaginationClick="onPaginationClick"
		></PaginationSlideshow>
	</div>
</template>

<script>
import PaginationSlideshow from "@/components/ui/PaginationSlideshow.vue";

export default {
	props: ["block", "blocks", "parentBlock", "card"],
	components: { PaginationSlideshow },
	data() {
		return {
			contents: [],
			numberOfPosts: 0,
			activeIndex: 0,
			currentIndex: 0,
			spaceBetween: 30,
			swiperOption: {
				slidesPerView: 1
			}
		};
	},
	computed: {
		swiper() {
			return this.$refs.swiper.$swiper;
		}
	},
	methods: {
		onSlideChange() {
			this.currentIndex = this.swiper.activeIndex;
		},
		onPaginationClick(index) {
			this.swiper.slideTo(index);
		}
	},
	mounted() {
		this.contents = this.parseAttribute(this.block.attributes.contents);
		this.numberOfPosts = this.contents.length;
	}
};
</script>

<style lang="scss" scoped>
.single-card {
	padding: $spacing-0;
	margin: 25px;
	overflow: hidden;
	box-shadow: 15px 13px 34px -17px rgba(0, 0, 0, 0.6);
}

h3,
h4 {
	margin-bottom: 1rem;
}
.single-card img {
	margin: -25px;
	margin-bottom: 25px;
	width: calc(100% + 50px);
	max-height: 300px;
	margin-bottom: 1rem;
	object-fit: cover;
}
.pagination {
	margin-top: $spacing-0;
}
.single-card.card {
	.content {
		background: #fff;
	}
}
@media only screen and (max-width: 768px) {
	.single-card {
		margin: 10px;
	}
}
@media only screen and (max-width: 550px) {
	.single-card {
		margin: 0;
		margin-bottom: 50px;
	}
}
</style>
