<template>
	<div class="our-mission background-all">
		<MainColumn>
			<div class="header">
				<h1 class="font-size-35">{{ block.attributes.title }}</h1>
				<p class="font-size-20 mt-4">{{ block.attributes.content }}</p>
			</div>
			<div class="targets">
				<swiper
					class="swipe"
					ref="swiper"
					:options="swiperOption"
					@slideChange="onSlideChange"
				>
					<swiper-slide
						class="slide"
						v-for="(target, i) in targets"
						:key="i"
					>
						<img
							class="image"
							:src="target.image.sizes.medium.url"
						/>
						<div
							v-html="target.content_target"
							class="content"
						></div>
					</swiper-slide>
				</swiper>
			</div>
			<PaginationSlideshow
				class="pagination"
				target="all"
				:currentIndex="currentIndex + 1"
				:numberOfPosts="numberOfPosts"
				@onPaginationClick="onPaginationClick"
			></PaginationSlideshow>
		</MainColumn>
	</div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import MainColumn from "@/components/ui/MainColumn";
import PaginationSlideshow from "@/components/ui/PaginationSlideshow.vue";

export default {
	props: ["block", "blocks", "parentBlock"],
	components: { MainColumn, PaginationSlideshow, Swiper, SwiperSlide },
	data() {
		return {
			activeSlide: null,
			currentIndex: 0,
			numberOfPosts: 0,
			swiperOption: {
				slidesPerView: 1,
				breakpoints: {
					550: {
						slidesPerView: 2,
						spaceBetween: 40,
						centeredSlides: true
					},
					768: {
						slidesPerView: 3,
						spaceBetween: 40
					},
					1024: {
						slidesPerView: 4,
						spaceBetween: 40
					}
				}
			},
			targets: []
		};
	},
	computed: {
		swiper() {
			return this.$refs.swiper.$swiper;
		}
	},
	methods: {
		onSlideChange() {
			this.currentIndex = this.swiper.activeIndex;
		},
		onPaginationClick(index) {
			this.swiper.slideTo(index);
		}
	},
	mounted() {
		this.targets = this.parseAttribute(this.block.attributes.target);
		this.numberOfPosts =
			window.innerWidth > 768
				? Math.ceil(this.targets.length / 3)
				: window.innerWidth > 550
				? Math.ceil(this.targets.length / 2)
				: this.targets.length;
	}
};
</script>

<style lang="scss" scoped>
.targets {
	margin: $spacing-0 0;
}
.our-mission {
	padding: $spacing-1 0;
}
.header {
	max-width: 600px;
}
.image {
	width: 90%;
	left: 50%;
	aspect-ratio: 1;
    object-fit: cover;
	position: relative;
	transform: translateX(-50%);
	border-radius: 100%;
	margin-bottom: 1rem;
	margin-top: 1rem;
}
</style>
